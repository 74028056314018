import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useRef, useState } from 'react';
import IconButton from '../../../components/Buttons/IconButton';
import { DocumentMetricType } from '../../../openApiClient';
import styles from './DocumentProcessingStats.module.scss';

const DOCUMENT_PROCESSING_STATS_SELECTED_METRICS = 'DOCUMENT_PROCESSING_STATS_SELECTED_METRICS';

const stat_metric_map: Record<DocumentMetricType, { name: string; shortName?: string; getMetric: (docStat: any) => any }> = {
    [DocumentMetricType.Total]: {
        name: 'Total',
        getMetric: (docStat: any) => docStat['totalCount'] || 0,
    },
    [DocumentMetricType.NeedTagging]: {
        name: 'Need Tagging',
        getMetric: (docStat: any) => docStat['needTaggingCount'] || 0,
    },
    [DocumentMetricType.ShouldGenerateTransactions]: {
        name: 'Generate Transactions',
        getMetric: (docStat: any) => docStat['shouldGenerateTransactionsCount'] || 0,
    },
    [DocumentMetricType.Training]: {
        name: 'Training (Sort/Extraction)',
        shortName: 'Training',
        getMetric: (docStat: any) => (docStat['trainingSortCount'] || 0) + '/' + (docStat['trainingExtractionCount'] || 0),
    },
    [DocumentMetricType.Review]: {
        name: 'Review (Sort/Extraction)',
        shortName: 'Review',
        getMetric: (docStat: any) => (docStat['postJobReviewRequiredSort'] || 0) + '/' + (docStat['postJobReviewRequiredExtraction'] || 0),
    },
};

const getStatTotal = (docStats: any[]) => {
    const trainingTotal = docStats.reduce(
        (acc, curr) => {
            return [acc[0] + (curr['trainingSortCount'] || 0), acc[1] + (curr['trainingExtractionCount'] || 0)];
        },
        [0, 0]
    );
    const reviewTotal = docStats.reduce(
        (acc, curr) => {
            return [acc[0] + (curr['postJobReviewRequiredSort'] || 0), acc[1] + (curr['postJobReviewRequiredExtraction'] || 0)];
        },
        [0, 0]
    );
    return {
        noClick: true,
        type: 'Total',
        totalCount: docStats.reduce((acc, curr) => acc + (curr[DocumentMetricType.Total] || 0), 0),
        needTaggingCount: docStats.reduce((acc, curr) => acc + (curr[DocumentMetricType.NeedTagging] || 0), 0),
        shouldGenerateTransactionsCount: docStats.reduce((acc, curr) => acc + (curr['shouldGenerateTransactionsCount'] || 0), 0),
        trainingSortCount: trainingTotal[0],
        trainingExtractionCount: trainingTotal[1],
        postJobReviewRequiredSort: reviewTotal[0],
        postJobReviewRequiredExtraction: reviewTotal[1],
    };
};

enum DocumentProcessingStatsMode {
    SelectableTypes = 'SelectableTypes',
    DisplayOnlyTypes = 'DisplayOnlyTypes',
}

const DEFAULT_SELECTED_METRICS = [DocumentMetricType.NeedTagging, DocumentMetricType.Review];

interface DocumentProcessingStatsProps {
    allDocStats: Array<any>;
    setSelectedTypes?: (docStat: any) => void;
    selectedTypes?: string[];
    includeTotalBlock?: boolean;
    includeTypes?: string[];
    leftComponent?: React.ReactNode;
    onChangeSelectedMetrics?: (metrics: DocumentMetricType[]) => void;
}

const DocumentProcessingStats: React.FC<DocumentProcessingStatsProps> = ({
    allDocStats,
    setSelectedTypes,
    selectedTypes,
    includeTotalBlock,
    includeTypes,
    leftComponent,
    onChangeSelectedMetrics,
}) => {
    const [hideStats, setHideStats] = useState(false);

    // which metrics are selected to display
    const [selectedMetrics, setSelectedMetrics] = useState<DocumentMetricType[]>(DEFAULT_SELECTED_METRICS);

    // toggle metric helper
    const toggleMetric = (metric: DocumentMetricType) => {
        const newMetrics = selectedMetricsRef.current.includes(metric) ? selectedMetricsRef.current.filter((m) => m !== metric) : [...selectedMetricsRef.current, metric];

        localStorage.setItem(DOCUMENT_PROCESSING_STATS_SELECTED_METRICS, JSON.stringify(newMetrics));
        selectedMetricsRef.current = newMetrics;

        setSelectedMetrics(newMetrics);
    };

    const mode = setSelectedTypes ? DocumentProcessingStatsMode.SelectableTypes : DocumentProcessingStatsMode.DisplayOnlyTypes;

    // ref to the selected metrics
    const selectedMetricsRef = useRef<DocumentMetricType[]>(DEFAULT_SELECTED_METRICS);

    // ref to the onChangeSelectedMetrics function
    const onChangeSelectedMetricsRef = useRef(onChangeSelectedMetrics);

    useEffect(() => {
        onChangeSelectedMetricsRef.current = onChangeSelectedMetrics;
    }, [onChangeSelectedMetrics]);

    // load selected metrics from local storage
    useEffect(() => {
        const storedMetrics = localStorage.getItem(DOCUMENT_PROCESSING_STATS_SELECTED_METRICS);
        if (storedMetrics) {
            setSelectedMetrics(JSON.parse(storedMetrics));
        }
    }, []);

    // delay telling the parent about any changes until a timeout to avoid unnecessary re-renders
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onChangeSelectedMetricsRef.current?.(selectedMetrics);
        }, 1500); // 500 milliseconds delay

        // Cleanup function to clear the timeout if the effect is triggered again
        return () => clearTimeout(timeoutId);
    }, [selectedMetrics]);

    // load hide stats from local storage
    useEffect(() => {
        const storedHideStats = localStorage.getItem('hideStats');
        if (storedHideStats) {
            setHideStats(JSON.parse(storedHideStats));
        }
    }, []);

    // save hide stats to local storage when it changes
    useEffect(() => {
        localStorage.setItem('hideStats', JSON.stringify(hideStats));
        onChangeSelectedMetricsRef.current?.(hideStats ? [] : selectedMetricsRef.current);
        console.log('hideStats', hideStats);
    }, [hideStats]);

    const renderMetricSelection = () => {
        return (
            <div className={`${styles.metricSelection}`}>
                {Object.entries(stat_metric_map).map(([statKey, statValue]) => {
                    return (
                        <div
                            key={`button${statKey}`}
                            className={`${styles.metricButton} ${selectedMetricsRef.current.includes(statKey as DocumentMetricType) ? styles.selected : ''}`}
                            onClick={() => toggleMetric(statKey as DocumentMetricType)}
                        >
                            {statValue.shortName || statValue.name}
                        </div>
                    );
                })}
            </div>
        );
    };

    const isTypeSelected = (type: string) => {
        if (mode === DocumentProcessingStatsMode.SelectableTypes && selectedTypes) {
            return selectedTypes.includes(type);
        }
        return false;
    };

    const handleStatClick = (type: string) => {
        const isSelected = isTypeSelected(type);
        // if setter function is provided
        if (setSelectedTypes) {
            // if no selected types, then just pass the type to be handled by the parent
            if (!selectedTypes) {
                setSelectedTypes?.(type);
            }
            // otherwise, toggle the type based on the selected types array
            else if (isSelected) {
                setSelectedTypes?.(selectedTypes?.filter((selectedType) => selectedType !== type));
            } else {
                setSelectedTypes?.(selectedTypes?.concat(type));
            }
        }
    };

    const renderStat = (docStat: any) => {
        const docType = docStat.type;

        const isSelected = isTypeSelected(docStat.type);

        const allowClick = !docStat.noClick;

        return (
            <div
                key={`docStat${docType}`}
                className={`${styles.stat} ${allowClick && isSelected ? styles.selected : ''} ${allowClick && mode === DocumentProcessingStatsMode.SelectableTypes ? styles.hoverable : ''}`}
                onClick={() => {
                    if (allowClick) {
                        handleStatClick(docStat.type);
                    }
                }}
            >
                <div className={`${styles.title}`}>{docType}</div>

                {Object.keys(stat_metric_map)
                    .filter((statKey) => selectedMetricsRef.current.includes(statKey as DocumentMetricType))
                    .map((statKey) => {
                        const { name, getMetric } = stat_metric_map[statKey as keyof typeof stat_metric_map];

                        return (
                            <div className={`${styles.statMetric}`} key={`statMetric${docType}${statKey}`}>
                                <div>{name}</div>
                                <div>{getMetric(docStat)}</div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    const allDocStatsToDisplay = [...allDocStats];

    const includeTypesUnique = includeTypes?.filter((type, index, self) => self.indexOf(type) === index);

    if (includeTypesUnique) {
        const missingTypes = includeTypesUnique.filter((type) => !allDocStats.some((docStat) => docStat.type === type));
        missingTypes.forEach((type) => {
            allDocStatsToDisplay.push({ type });
        });
    }

    if (includeTotalBlock && allDocStatsToDisplay.length > 1) {
        const totalStat = getStatTotal(allDocStatsToDisplay);
        allDocStatsToDisplay.unshift(totalStat);
    }

    return (
        <div className={`${styles.main}`}>
            <div className={`${styles.controlBar}`}>
                {leftComponent || <div />}
                <div className={`${styles.controls}`}>
                    {!hideStats ? renderMetricSelection() : <div className={`${styles.hiddenLabel}`}>Document Stats</div>}
                    <IconButton
                        faIcon={hideStats ? faEyeSlash : faEye}
                        onClick={() => {
                            setHideStats(!hideStats);
                        }}
                        title={hideStats ? 'Show Stats' : 'Hide Stats'}
                    />
                </div>
            </div>
            {!hideStats && selectedMetricsRef.current.length > 0 && <div className={`${styles.statList}`}>{allDocStatsToDisplay.map((docStat, i) => renderStat(docStat))}</div>}
        </div>
    );
};

export default DocumentProcessingStats;
