import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import api from '../../../api';
import { getGlobalInvestmentFilterQuery, subscribeGlobalInvestmentFilterChange } from '../../../common/getSetGlobalInvestmentFilter';
import { EM_DASH } from '../../../constants/constantStrings';
import '../../../styles/widget-DocumentsCollected.css';
import '../../../styles/widget.css';
import { Investment } from '../../../types';
import { toQueryString } from '../../../utilities/apiHelpers/queryString';
import { getUTCFullYear } from '../../../utilities/date/dateToUTCMidnight';
import EmptyOverlay from '../../EmptyStates/EmptyOverlay';
import emptyStateData from '../../EmptyStates/emptyStateFakeData';
import IconTooltip from '../../Icons/IconTooltip';

interface DocumentsCollectedProps {
    userId: string;
    investments: any[];
    forceUpdate: boolean;
    vue: string;
    history: any;
    account: any;
    containerStyle?: React.CSSProperties;
    small?: boolean;
    slim?: boolean;
    loading?: (progress: number, key: string) => void;
    loaded?: (key: string) => void;
}

const DocumentsCollected: React.FC<DocumentsCollectedProps> = (props) => {
    const [loaded, setLoaded] = useState(false);
    const [allStats, setAllStats] = useState<any>(null);

    const prevInvestmentsRef = useRef<Investment[] | null>(null);

    useEffect(() => {
        const unsubscribe = subscribeGlobalInvestmentFilterChange(() => loadData());
        return () => unsubscribe();
    }, []);

    const loadData = useCallback(async () => {
        if (!props.userId) return;

        props.loading?.(0, 'documentsCollected_loadData');
        const queryObj = {
            userId: props.userId,
            ...getGlobalInvestmentFilterQuery(props.vue),
            investments: props.investments.map((i) => i._id).join(','),
        };
        try {
            const response = await api.get(`/investments/documentscollected?${toQueryString(queryObj)}`);
            setAllStats(response); // Ensure response.data is set correctly
        } catch (error) {
            setAllStats({});
            console.error('Error loading document stats:', error);
        } finally {
            setLoaded(true);
            props.loaded?.('documentsCollected_loadData');
        }
    }, [props]);

    useEffect(() => {
        if (!_.isEqual(prevInvestmentsRef.current, props.investments)) {
            loadData();
            prevInvestmentsRef.current = props.investments;
        }
    }, [props.investments, loadData]);

    const seeAll = () => {
        props.history.push(`/accounts/${props?.account?._id ?? 'all'}/taxcenter`);
    };

    const getStyle = () => {
        let style: React.CSSProperties = {};
        if (props.small) {
            style = {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                boxShadow: 'none',
            };
        }
        return style;
    };

    const getPercent = (stats: any) => {
        if (!stats) return EM_DASH;
        const { received, expected } = stats;
        let percent = EM_DASH;
        if (isFinite(received) && isFinite(expected)) {
            percent = Math.round((received / expected) * 100).toString();
            if (!isFinite(Number(percent))) percent = '0';
        }
        return percent;
    };

    const getStats = () => {
        let stats = allStats?.[props.account?._id ?? 'total'];

        if (stats && stats?.expected < stats?.received) {
            stats.expected = stats.received;
            console.log('ERROR in DocumentsCollected.render:', 'stats.expected < stats.received,', 'setting expected to received value', stats);
        }

        return stats;
    };

    const taxYear = getUTCFullYear() - 1;
    let stats = getStats();
    let showEmptyState = !stats && allStats && loaded && props.userId;

    if (showEmptyState) {
        stats = emptyStateData.DocumentsWidget.stats;
    }

    const percent = getPercent(stats);

    return (
        <EmptyOverlay isEmpty={showEmptyState} emptyText={emptyStateData.DocumentsWidget.emptyText} textStyle={emptyStateData.DocumentsWidget.textStyle}>
            <div
                className="dc-container widget"
                style={{
                    ...getStyle(),
                    ...props.containerStyle,
                }}
            >
                <div className="dc-chart-container">
                    <div className={props.slim ? 'dc-slim-header-container' : 'dc-header-container'}>
                        {props.slim && (
                            <div className="dc-slim-header">
                                <h3>Tax Center</h3>
                                <div className="a" onClick={seeAll}>
                                    see all
                                </div>
                            </div>
                        )}
                        <div
                            className="dc-header"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img src="/images/icons/smallDoc.png" alt="documents"></img>
                            <h1
                                style={
                                    !props.small
                                        ? {
                                              fontSize: '24px',
                                              marginBottom: '0px',
                                          }
                                        : {
                                              fontSize: '16px',
                                              marginBottom: '0px !important',
                                              padding: 0,
                                              marginLeft: '-5px',
                                          }
                                }
                            >
                                {taxYear + ' Documents Collected'}
                            </h1>
                            <IconTooltip
                                text={'This only reflects investments that are either active, or were set as inactive this year.'}
                                containerStyle={{ marginLeft: '15px' }}
                                popupStyle={{ marginTop: '100px', marginLeft: '-180px' }}
                            />
                            <span style={!props.small ? {} : { fontSize: '16px' }}>{isFinite(Number(percent)) ? percent : EM_DASH}%</span>
                        </div>
                    </div>
                    <div
                        className={`dc-bar-container ${props.small ? 'dc-bar-container-small' : ''}`}
                        style={{
                            position: 'relative',
                            marginTop: props.small ? undefined : '73px',
                        }}
                    >
                        <div className="dc-bar" />
                        <div className="dc-bar-filled" style={{ width: `${percent}%` }} />
                    </div>
                    {!props.slim && !props.small && (
                        <div className="dc-info">
                            Investments With Tax Documents: {stats?.received ?? 0} of {stats?.expected ?? 0} investments
                        </div>
                    )}
                    {props.small && (
                        <>
                            <div className="dc-info-small">
                                {stats?.received ?? 0} of {stats?.expected ?? 0} investments
                            </div>
                        </>
                    )}
                </div>
            </div>
        </EmptyOverlay>
    );
};

export default DocumentsCollected;
