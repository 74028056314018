import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

// components
import GenericDrawer from './GenericDrawer';

// utilities
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

// style
import '../../styles/addEditAdvisorDrawer.css';

interface AdvisorGroupMembership {
    group: string;
    role: string;
    deleted_at?: string;
}

interface Advisor {
    _id: string;
    name: string;
    email: string;
    email_verified: boolean;
    advisor_group_memberships: AdvisorGroupMembership[];
}

export interface Props extends Partial<RouteComponentProps> {
    advisor?: Advisor;
    firm?: string;
    newFirmAndOwner?: boolean;
    startName?: string;
    startEmail?: string;
    user?: {
        access: string;
    };
    loading: (time?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string, isError?: boolean) => void;
    reloadData: () => void;
    close: () => void;
    advisorGroups?: Array<{ name: string; _id: string }>;
    roles: string[];
    setErrors?: (errors: Record<string, string>) => void;
}

interface CommonData {
    email?: string;
    name?: string;
    firm?: { _id: string };
    role?: string;
    password?: string;
    confirmPassword?: string;
}

interface ApiError {
    message: string;
    code?: number;
}

const AddEditAdvisorDrawer: React.FC<Props> = (props) => {
    const [commonData, setCommonData] = useState<CommonData>({});
    const [localErrors, setLocalErrors] = useState<Record<string, string | null>>({});
    const [addExistingUser] = useState(false);
    const [resetClicked, setResetClicked] = useState(false);
    const [deactivateClicked, setDeactivateClicked] = useState(false);
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const loadData = async () => {
        if (!mounted.current) return;

        props.loading?.(0, 'addEditAdvisorDrawer');

        try {
            let data = {};

            if (props.advisor) {
                data = {
                    name: props.advisor.name,
                    email: props.advisor.email,
                    role: props.advisor.advisor_group_memberships?.[0]?.role,
                    firm: { _id: props.advisor.advisor_group_memberships?.[0]?.group },
                };
            } else if (props.firm) {
                data = {
                    firm: { _id: props.firm },
                };
            } else if (props.newFirmAndOwner) {
                data = {
                    role: 'Owner',
                    name: props.startName ?? '',
                    email: props.startEmail ?? '',
                    firm: { _id: props.firm ?? '' },
                };
            }

            if (mounted.current) {
                setCommonData(data);
            }

            props.loaded?.('addEditAdvisorDrawer');
            return data;
        } catch (error) {
            console.error('Error loading advisor data:', error);
            if (mounted.current) {
                setLocalErrors((prev) => ({
                    ...prev,
                    submit: 'Error loading advisor data',
                }));
            }
            props.loaded?.('addEditAdvisorDrawer');
            return {};
        }
    };

    // Load initial data
    useEffect(() => {
        loadData();
    }, [props.advisor, props.firm, props.newFirmAndOwner]);

    // Update when props change
    useEffect(() => {
        if (!_.isEqual(props.advisor?.advisor_group_memberships?.[0]?.group, commonData?.firm?._id) || !_.isEqual(props.firm, commonData?.firm?._id)) {
            loadData();
        }
    }, [props.advisor, props.firm]);

    const getFields = (state: any, setState: any) => {
        let fields: Record<string, any> = {};

        if (!addExistingUser) {
            fields['name'] = {
                label: 'Full Name',
                placeholder: 'Full Name',
                fieldName: 'name',
                type: 'text',
                // value: state?.commonData?.name,
            };
        }

        fields['email'] = {
            label: 'Email Address',
            placeholder: 'Email Address',
            fieldName: 'email',
            type: 'text',
            // value: state?.commonData?.email,
        };

        if (!props.firm && !props.newFirmAndOwner) {
            fields['firm'] = {
                placeholder: 'Select Firm Name',
                label: 'Firm Name',
                fieldName: 'firm',
                type: 'select',
                options: props.advisorGroups,
                optionField: 'name',
                optionClearable: false,
                // value: state?.commonData?.firm,
            };
        }

        if (!props.newFirmAndOwner) {
            fields['role'] = {
                placeholder: 'Select Role',
                label: 'Role',
                fieldName: 'role',
                type: 'select',
                options: ['Member', 'Admin', 'Owner'],
                optionClearable: false,
            };
        }

        return fields;
    };

    const checkCommonData = (data: CommonData, errors: Record<string, string>) => {
        if (!data.email) errors['email'] = 'This field is required.';

        if (!addExistingUser) {
            if (!data.name) errors['name'] = 'This field is required.';
            // if (!data.firm) errors['firm'] = 'This field is required.';
            if (!data.role) errors['role'] = 'This field is required.';
        }

        // if creating new user
        if (!props.advisor && !addExistingUser) {
            // password no longer required
            // if (!data.password)
            //     errors['password'] = 'This field is required.'
            // if (!data.confirmPassword)
            //     errors['confirmPassword'] = 'This field is required.'
            if ((data.password || data.confirmPassword) && data.password !== data.confirmPassword) {
                errors['confirmPassword'] = 'Passwords do not match.';
            }
        }
    };

    const isSafeToSave = (setErrors = true, data: CommonData): boolean => {
        let errors: Record<string, string> = {};
        checkCommonData(data, errors);
        const isSafe = Object.keys(errors).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? '' : 'There are missing required fields.';
            setLocalErrors(isSafe ? {} : errors);
        }

        return isSafe;
    };

    const createUser = async (name: string, email: string, password: string, access: string) => {
        const newUserBody = { name, email, password, access };
        const newUserRes = await api.post('/users', newUserBody, {
            400: (err: ApiError) => setLocalErrors({ ...localErrors, email: err.message }),
            406: (err: ApiError) => setLocalErrors({ ...localErrors, password: err.message }),
            500: (err: ApiError) => setLocalErrors({ ...localErrors, submit: err.message }),
        });
        return newUserRes?.message === 'OK' ? newUserRes.user : null;
    };

    const addUserToAdvisoryGroup = async (firm: string, userId: string, role: string) => {
        const newAdvisorBody = { userId, role };
        const newAdvisorRes = await api.post(`/advisorygroups/${firm}/advisors`, newAdvisorBody);
        return !!newAdvisorRes?.success;
    };

    const createOrUpdateAdvisor = async (data: CommonData) => {
        if (!isSafeToSave(true, data)) {
            props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
            return;
        }
        const mergedData = {
            ...commonData,
            ...data,
        };

        props.loading(320, 'addEditAdvisorDrawer-createOrUpdateAdvisor');

        let commonDataCopy = JSON.parse(JSON.stringify(mergedData));
        const createdByAdmin = props.user?.access === 'admin';
        try {
            // create group for new firm and owner creation
            if (props.newFirmAndOwner) {
                const user = await createUser(commonDataCopy.name, commonDataCopy.email, commonDataCopy.password, 'advisor');

                if (user) {
                    // create group
                    const advisoryGroupRes = await api.post(`/advisorygroups`, {
                        name: commonDataCopy.name,
                        owner_id: user._id,
                    });
                    if (advisoryGroupRes) {
                        commonDataCopy.firm = advisoryGroupRes.advisoryGroup;
                        setDismissableAlert(props.setAlert, 'User and group created.');
                        props.reloadData();
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                        props.close();
                        return;
                    } else {
                        setDismissableAlert(props.setAlert, 'Error creating group.', true);
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                        return;
                    }
                } else {
                    setDismissableAlert(props.setAlert, 'Error creating user.', true);
                    props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                    return;
                }
            }

            // create user and add to group
            if (!props.advisor) {
                let user;
                if (!addExistingUser) {
                    user = await createUser(commonDataCopy.name, commonDataCopy.email, commonDataCopy.password, 'advisor');
                } else {
                    setDismissableAlert(props.setAlert, 'Error, you do not have the permissions to add an existing user since you are not an admin');
                    props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                    return;
                }

                if (user && createdByAdmin) {
                    const advisorSuccess = await addUserToAdvisoryGroup(commonDataCopy.firm._id, user._id, commonDataCopy.role);

                    if (advisorSuccess) {
                        // successfully created by admin
                        setDismissableAlert(props.setAlert, `New advisor created.`);
                        props.reloadData();
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                        props.close();
                    } else {
                        setDismissableAlert(props.setAlert, 'Error adding advisor to group', true);
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                    }
                } else if (user) {
                    // /:groupId/advisors/:advisorId
                    const updateAdvisorRes = await api.patch(`/advisorygroups/${commonDataCopy.firm._id}/advisors/${user._id}`, { role: commonDataCopy.role });
                    if (updateAdvisorRes) {
                        // successfully created by advisor
                        setDismissableAlert(props.setAlert, `New advisor created.`);
                        props.reloadData();
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                        props.close();
                    } else {
                        setDismissableAlert(props.setAlert, `Error creating new advisor.`);
                        props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                    }
                } else {
                    setDismissableAlert(props.setAlert, 'Error creating user.', true);
                    props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                }
            }
            // edit existing advisor
            else {
                const agm = props.advisor.advisor_group_memberships[0];
                const userUpdateRes = await api.patch(`/users/${props.advisor._id}`, { ...commonData, name: commonDataCopy.name, email: commonDataCopy.email });

                const advisorUpdateRes = await api.patch(`/advisorygroups/${agm.group}/advisors/${props.advisor._id}`, { role: commonDataCopy.role });
                if (userUpdateRes && advisorUpdateRes) {
                    setDismissableAlert(props.setAlert, 'Advisor data updated.');
                    props.reloadData();
                    props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                    props.close();
                } else {
                    setDismissableAlert(props.setAlert, 'Error updating user data.', true);
                    props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
                }
            }
        } catch (error) {
            console.error('Error in createOrUpdateAdvisor:', error);
            setDismissableAlert(props.setAlert, 'An error occurred while processing your request.', true);
            props.loaded('addEditAdvisorDrawer-createOrUpdateAdvisor');
        }
    };

    const resendValidationEmail = async (email: string) => {
        const res = await api.post(`/users/reverify`, { email });
        if (res?.success) {
            setDismissableAlert(props.setAlert, 'Validation email resent.');
        } else {
            setDismissableAlert(props.setAlert, 'Error sending validation email.', true);
        }
    };

    const resetPassword = async (e: React.MouseEvent, advisor: Advisor) => {
        e.stopPropagation();
        if (!resetClicked) {
            setResetClicked(true);
            setTimeout(() => setResetClicked(false), 1000);
        } else {
            const resetRes = await api.post(
                '/users/forgot/',
                { email: advisor.email },
                {
                    500: (err: any) => console.error(err),
                }
            );
            if (resetRes) {
                setDismissableAlert(props.setAlert, 'Password reset.');
            } else {
                setDismissableAlert(props.setAlert, 'Error resetting password.', true);
            }
        }
    };

    const deactivateAdvisor = async (e: React.MouseEvent, advisor: Advisor) => {
        e.stopPropagation();
        const agm = advisor.advisor_group_memberships[0];
        if (!deactivateClicked) {
            setDeactivateClicked(true);
            setTimeout(() => setDeactivateClicked(false), 1000);
        } else {
            if (props.advisor) {
                const deleteRes = await api.delete(`/advisorygroups/${agm.group}/advisors/${props.advisor._id}`);
                if (deleteRes.success) {
                    setDismissableAlert(props.setAlert, 'Advisor deleted.');
                    props.reloadData();
                    props.close();
                } else {
                    setDismissableAlert(props.setAlert, 'Error deleting advisor.', true);
                }
            } else {
                setDismissableAlert(props.setAlert, 'Advisor data is missing.', true);
            }
        }
    };

    const renderUserInfo = () => {
        if (!props.advisor) {
            return null;
        }

        const agm = props.advisor.advisor_group_memberships[0];

        return (
            <div className="advisor-info-content">
                <h3 style={{ textAlign: 'left' }}>Password</h3>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span>*************</span>
                    <span className="a" onClick={(e) => props.advisor && resetPassword(e, props.advisor)}>
                        {resetClicked ? 'Confirm' : 'Reset Password'}
                    </span>
                </div>

                <h3 style={{ textAlign: 'left' }}>Status</h3>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span>{agm.deleted_at ? 'Deactivated' : 'Active'}</span>
                    <span className="a" style={{ color: 'var(--color-dark-red)' }} onClick={(e) => props.advisor && deactivateAdvisor(e, props.advisor)}>
                        {deactivateClicked ? 'Confirm' : 'Deactivate'}
                    </span>
                </div>

                {!props.advisor.email_verified && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                        }}
                    >
                        <span
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{
                                    marginRight: '20px',
                                    cursor: 'pointer',
                                }}
                                src="/images/icons/send.png"
                                alt="Resend email"
                                title="Resend email"
                                onClick={() => props.advisor?.email && resendValidationEmail(props.advisor.email)}
                            />
                            Never Logged In - Resend email
                        </span>
                    </div>
                )}
            </div>
        );
    };

    const onFieldChange = (field: string, value: any) => {
        const newData = { ...commonData, [field]: value }; // Create a copy of current data

        setCommonData(newData);
        return newData;
    };

    return (
        <GenericDrawer
            {...props}
            title={
                props.advisor
                    ? 'Edit Advisor'
                    : props.newFirmAndOwner
                      ? 'Setup New Firm & Owner'
                      : `Add${!addExistingUser ? ' New' : ''} Advisor${!props.firm ? ' to Existing Firm' : ''}`
            }
            actionStr={props.advisor ? 'Save Changes' : 'Add'}
            loadingKey="addEditAdvisorDrawer"
            getFields={getFields}
            onSubmit={createOrUpdateAdvisor}
            close={props.close}
            loading={props.loading}
            loaded={props.loaded}
            loadData={loadData}
            className="addNewAdvisorToFirm-drawer"
            isSafeToSave={isSafeToSave}
            setErrors={setLocalErrors}
            errors={localErrors as Record<string, string>}
            formData={commonData}
            onFieldChange={onFieldChange}
        >
            {props.advisor && renderUserInfo()}
        </GenericDrawer>
    );
};

export default AddEditAdvisorDrawer;
