import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CopyButton } from '../../components/Buttons/IconButton';
import IconTextButton from '../../components/Buttons/IconTextButton';
import PaginationTable from '../../components/Tables/PaginationTable';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import { Integration } from '../../types/Integration';
import { User } from '../../types/User';
import IntegrationDrawer from './IntegrationDrawer';

interface IntegrationTableProps extends RouteComponentProps {
    user: User;
    setTitle: (title: React.ReactNode) => void;
    loading: () => void;
    loaded: () => void;
    vue: string;
    openDrawer: (content: () => React.ReactNode) => void;
    closeDrawer: () => void;
    setAlert: (message: string) => void;
}

const IntegrationTable: React.FC<IntegrationTableProps> = (props) => {
    const [errorUnit, setErrorUnit] = useState<'percent' | 'number'>('percent');
    const [childCallables, setChildCallables] = useState<{ loadData?: () => void; filterData?: (value: string, field: string) => void } | null>(null);
    const [typeFilter] = useState<{ _id: string | null } | null>(null);
    // const [searchString, setSearchString] = useState<string>('');

    const { setTitle, loading, loaded, user, vue, history, openDrawer, closeDrawer, setAlert } = props;

    useEffect(() => {
        const load = async () => {
            if (vue !== VUES.ADMIN) {
                console.log('not admin, redirect to home');
                history.push('/');
                return;
            }

            loading();
            // Simulate an API call
            setTimeout(() => {
                loaded();
            }, 1000);
        };

        load();
        loadQueryParams();
    }, [vue, history, loading, loaded]);

    const prevUserName = useRef(user?.name);

    useEffect(() => {
        if (user?.name && user?.name !== prevUserName.current) {
            prevUserName.current = user.name; // Update the ref to the current user name
            setTitle(
                <>
                    <span className="title-account-name">{user?.name}</span>
                    <div className="separator" />
                    Integrations
                </>
            );
        } else {
            return;
        }
    }, [user, setTitle]);

    const loadQueryParams = useCallback(() => {
        // const url = new URL(window.location.href);
        // Load query params logic here
    }, []);

    const getRoute = () => {
        return `/integrations?populate_connection_counts=true`;
    };

    const getColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                sort: {
                    field: 'name',
                },
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const name = integ.name;
                    return (
                        <td key={'integ_name' + i} title={`${name ?? EM_DASH}`}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                {name}
                                <CopyButton text={integ._id ?? ''} title={`Copy ID to Clipboard (${integ._id ?? ''})`} />
                            </div>
                        </td>
                    );
                },
            },
            {
                title: 'Total Connections',
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const areConnections = integ.connections_count > 0;
                    const totalConnClass = 'center-text' + (areConnections ? ' a' : '');
                    return (
                        <td
                            key={'nConn' + i}
                            className={totalConnClass}
                            onClick={() => {
                                if (!areConnections) {
                                    return;
                                }
                                const path = window.location.pathname.replace('integrations', `connections?integration=${integ._id}`);
                                history.push(path);
                            }}
                        >
                            {areConnections ? integ.connections_count : EM_DASH}
                        </td>
                    );
                },
            },
            {
                title: 'Has Active Integration Worker',
                sort: {
                    field: 'has_worker',
                },
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const hasActiveWorker = integ.has_worker;
                    return (
                        <td key={'hasActiveWorker' + i} className="center-text">
                            {hasActiveWorker ? 'Yes' : 'No'}
                        </td>
                    );
                },
            },
            {
                title: 'Worker Version',
                sort: {
                    field: 'worker_version',
                },
                headerClass: 'center-text',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const workerVersion = integ.worker_version;
                    return (
                        <td key={'workerVersion' + i} className="center-text">
                            {workerVersion}
                        </td>
                    );
                },
            },
            {
                title: 'Errors',
                headerClass: 'center-text',
                renderSubHeader: renderErrorsHeader,
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const integ = items[i];
                    const areErrs = integ.errored_connections_count;
                    const totalErrClass = 'center-text' + (areErrs ? ' a' : '');
                    const path = window.location.pathname.replace('integrations', `connections?integration=${integ._id}&connectionStatus=Error`);
                    return (
                        <td key={'errors' + i} className={totalErrClass}>
                            {areErrs ? (
                                <a href={path} style={{ color: 'inherit' }}>
                                    {getErrorCount(integ)}
                                </a>
                            ) : (
                                EM_DASH
                            )}
                        </td>
                    );
                },
            },
            {
                title: 'Edit',
                render: (col: any, row: any, items: Integration[], i: number) => {
                    const item = items[i];
                    return (
                        <td key={'ral_editEntity' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="xl"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openAddIntegrationDrawer(item);
                                }}
                            />
                        </td>
                    );
                },
            },
        ];
    };

    const changeErrorUnit = (unit: 'percent' | 'number') => {
        setErrorUnit(unit);
    };

    const getErrorCount = (integration: Integration, errorUnitOverride?: 'percent' | 'number') => {
        const errorUnitToUse = errorUnitOverride ?? errorUnit;
        if (errorUnitToUse === 'percent') {
            if (!integration.connections_count || !integration.errored_connections_count) {
                return '0%';
            }
            return `${((integration.errored_connections_count / integration.connections_count) * 100).toFixed(2)}%`;
        } else return integration.errored_connections_count;
    };

    const renderErrorsHeader = () => {
        return (
            <React.Fragment>
                <span
                    className={errorUnit === 'number' ? 'active a' : 'a'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeErrorUnit('number');
                    }}
                >
                    #
                </span>
                <span style={{ margin: '0 10px' }}>|</span>
                <span
                    className={errorUnit === 'percent' ? 'active a' : 'a'}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeErrorUnit('percent');
                    }}
                >
                    %
                </span>
            </React.Fragment>
        );
    };

    const getSelectFilters = () => {
        return [];
    };

    const openAddIntegrationDrawer = (integration?: Integration | null) => {
        openDrawer(() => (
            <IntegrationDrawer
                loading={loading || (() => {})}
                loaded={loaded || (() => {})}
                closeDrawer={closeDrawer || (() => {})}
                setAlert={setAlert}
                integration={integration}
                reloadData={childCallables?.loadData || (() => {})}
            />
        ));
    };

    // const filterMessages = (value: string) => {
    //     setQueryParam('search', value);
    //     setSearchString(value);
    //     childCallables?.filterData?.(value, 'name');
    // };

    // const getSubRowColumns = () => {
    //     return [
    //         {
    //             title: 'Investment Name',
    //             field: 'name',
    //         },
    //         {
    //             title: '# Clients',
    //             render: (col: any, row: any, items: Integration[], i: number) => {
    //                 const item = items[i];
    //                 const investmentCount = item?.investments?.length ?? 0;
    //                 return <td key={'ral_signed' + i}>{investmentCount}</td>;
    //             },
    //         },
    //     ];
    // };

    return (
        <>
            <div>
                <PaginationTable
                    noInvestmentDropdown={true}
                    selectFilters={getSelectFilters()}
                    containerStyle={{ margin: '0px' }}
                    name="processor"
                    initialSortField="name"
                    initialSortAscending={true}
                    loading={loading}
                    loaded={loaded}
                    disableDefaultOption={true}
                    noTypeDropdown={true}
                    noDateFilters={true}
                    useApi2={true}
                    getResultsFromResponse={(res: { integrations: Integration[] }) => res.integrations}
                    route={getRoute()}
                    title={
                        <>
                            Integrations
                            <IconTextButton icon="/images/icons/plus.png" text="New Integration" onClick={() => openAddIntegrationDrawer(null)} />
                        </>
                    }
                    searchBoxes={[
                        {
                            title: 'Search',
                            placeholder: 'Integration name',
                            queryKey: 'search',
                        },
                    ]}
                    columns={getColumns()}
                    setCallables={setChildCallables}
                    selectedUserId={typeFilter?._id}
                    vue={vue}
                    disableTypeFilter={true}
                    postProcessData={(data: Integration[]) => {
                        return data;
                    }}
                    enableClickSelect={true}
                    csvTitle={'Integrations'}
                    getCsvHeaders={() => {
                        return ['ID', 'Name', 'Total Connections', 'Has Active Integration Worker', 'Worker Version', 'Error Count', 'Error Percentage'];
                    }}
                    csvRow={(item: any) => {
                        return [
                            item._id,
                            item.name,
                            item.connections_count,
                            item.has_worker ? 'Yes' : 'No',
                            item.worker_version,
                            getErrorCount(item, 'number'),
                            getErrorCount(item, 'percent'),
                        ];
                    }}
                />
            </div>
            <div style={{ clear: 'both', height: '100px' }} />
        </>
    );
};

export default IntegrationTable;
