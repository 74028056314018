import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import api2 from '../../api2';
import { CURRENCIES, getCurrencyString } from '../../constants/currencyConstants';
import { getInvestmentSubtypes } from '../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';
import '../../styles/addConnectionDrawer.css';
import { Currency } from '../../types/Currency';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import AuditLogDisplay from '../AuditLogDisplay';
import DrawerContent from '../DrawerComponents/DrawerContent';
import EditExternalIds from '../EditExternalIds';
import { AccountSearchFilter, ConnectionSearchFilter } from '../Filters/GenericSearchFilter';
import InvestmentHiddenToggle from '../Toggles/InvestmentHiddenToggle';
import InvestmentInactiveToggle from '../Toggles/InvestmentInactiveToggle';

interface Investment {
    _id: string;
    name: string;
    user: string;
    type: string;
    currency?: string;
    inactive?: boolean;
    hidden?: boolean;
    investment_data?: {
        type?: string;
        contraAccountNumber?: string;
    };
    attributes?: {
        addepar_loa_status_workflow_id?: string;
        addepar_portal_status_workflow_id?: string;
    };
    audit_log?: any[];
    inactive_date?: string;
    account?: any;
    connection?: any;
    external_ids?: Record<string, string>;
}

interface EditInvestmentDrawerContentProps {
    investmentId?: string;
    accounts: any[];
    types?: string[];
    taxDocumentTypes?: string[];
    relevantUser?: any;
    loading: (time: number, key: string) => void;
    loaded: (key: string) => void;
    setAlert: (message: string, isError: boolean, duration?: number) => void;
    closeDrawer: () => void;
    reloadData: () => void;
    onSubmit?: (investment: any, submissionObj: any) => void;
    onNewAssetManager?: (manager: any) => void;
    onNewTaxContact?: (contact: any) => void;
    vue?: string;
}

interface InputField {
    title: string;
    type: 'text' | 'custom' | 'dropdown' | 'toggle' | 'password' | 'checkbox' | 'dropdown_section';
    alt?: string;
    defaultValue?: any;
    value?: any;
    onChange: (val: string | { title: string } | number) => void;
    options?: Array<{ title: string; value: string }>;
    excludeNull?: boolean;
    component?: React.ReactNode;
}

const EditInvestmentDrawerContent: React.FC<EditInvestmentDrawerContentProps> = ({
    investmentId,
    accounts = [],
    types = [],
    taxDocumentTypes = [],
    relevantUser,
    loading,
    loaded,
    setAlert,
    closeDrawer,
    reloadData,
    onSubmit,
    onNewAssetManager,
    onNewTaxContact,
    vue,
}) => {
    const [state, setState] = useState<{
        investment: Investment | null;
        prevInvestmentId?: string;
        newlyCreatedAssetMgr: any | null;
        newlyCreatedTaxCtc: any | null;
        name: string;
        investment_type: string;
        tax_document: string;
        typeOptions: any[];
        taxDocumentTypeOptions: any[];
        investment_subtype: string;
        investment_currency: string;
        contraAccountNumber: string;
        addepar_loa_status_workflow_id: string;
        addepar_portal_status_workflow_id: string;
        inactive: boolean;
        hidden: boolean;
        expected_tax_documents: boolean;
        editingInvestmentEntity: any | null;
        editingConnection: any | null;
        inactive_date?: string | undefined;
    }>({
        investment: null,
        newlyCreatedAssetMgr: null,
        newlyCreatedTaxCtc: null,
        name: '',
        investment_type: '',
        tax_document: '',
        typeOptions: [],
        taxDocumentTypeOptions: [],
        investment_subtype: '',
        investment_currency: '',
        contraAccountNumber: '',
        addepar_loa_status_workflow_id: '',
        addepar_portal_status_workflow_id: '',
        inactive: false,
        hidden: false,
        expected_tax_documents: false,
        editingInvestmentEntity: null,
        editingConnection: null,
        inactive_date: undefined,
    });

    const updateState = useCallback((updates: Partial<typeof state>) => {
        setState((prev) => ({
            ...prev,
            ...updates,
        }));
    }, []);

    const updateInvestmentFields = useCallback(async () => {
        if (!investmentId) return;

        loading(320, 'investmentDrawer');

        try {
            const response = await api2.client.InvestmentApi.getInvestment({
                investment_id: investmentId,
                populate_user: true,
                populate_account: true,
                populate_investment_master: true,
                populate_connection: true,
            });

            const investment = response.data.investment;

            if (!investment) {
                throw new Error('Investment not found');
            }
            updateState({
                investment: investment as Investment,
                name: investment.name || '',
                investment_type: investment.type || '',
                contraAccountNumber: _.get(investment, 'investment_data.contraAccountNumber', ''),
                addepar_loa_status_workflow_id: _.get(investment, 'attributes.addepar_loa_status_workflow_id', ''),
                addepar_portal_status_workflow_id: _.get(investment, 'attributes.addepar_portal_status_workflow_id', ''),
                inactive: Boolean(investment.inactive),
                hidden: Boolean(investment.hidden),
                expected_tax_documents: Boolean(investment.expected_tax_documents),
                investment_subtype: _.get(investment, 'investment_data.type', ''),
                investment_currency: investment.currency || '',
                editingInvestmentEntity: investment.account || null,
                editingConnection: investment.connection || null,
            });
        } catch (err) {
            console.error('Error loading investment:', err);
            setDismissableAlert(setAlert, 'Could not load investment', true);
        } finally {
            loaded('investmentDrawer');
        }
    }, [investmentId, loading, loaded, setAlert, updateState]);

    const generateOptions = (): void => {
        let typeOptions = types?.map?.((t: string) => {
            return { title: t };
        });
        let taxDocumentTypeOptions = taxDocumentTypes?.map?.((tdt: string) => {
            return { title: tdt };
        });

        setState((prevState) => ({
            ...prevState,
            typeOptions,
            taxDocumentTypeOptions,
        }));
    };

    useEffect(() => {
        generateOptions();
    }, [types, taxDocumentTypes]);

    useEffect(() => {
        let mounted = true;

        const init = async () => {
            if (mounted) {
                await updateInvestmentFields();
                generateOptions();
            }
        };

        init();

        return () => {
            mounted = false;
        };
    }, [updateInvestmentFields]);

    useEffect(() => {
        if (investmentId) {
            updateInvestmentFields();
        }
    }, [investmentId, updateInvestmentFields]);

    useEffect(() => {
        if (state.newlyCreatedAssetMgr && onNewAssetManager) {
            onNewAssetManager(state.newlyCreatedAssetMgr);
        }
    }, [state.newlyCreatedAssetMgr, onNewAssetManager]);

    useEffect(() => {
        if (state.newlyCreatedTaxCtc && onNewTaxContact) {
            onNewTaxContact(state.newlyCreatedTaxCtc);
        }
    }, [state.newlyCreatedTaxCtc, onNewTaxContact]);

    const validateForm = (): boolean => {
        if (!state.name?.trim()) {
            setDismissableAlert(setAlert, 'Name is required', true);
            return false;
        }

        if (!state.investment_type) {
            setDismissableAlert(setAlert, 'Investment type is required', true);
            return false;
        }

        if (!state.investment_currency) {
            setDismissableAlert(setAlert, 'Currency is required', true);
            return false;
        }

        if (!state.editingInvestmentEntity?._id) {
            setDismissableAlert(setAlert, 'Account is required', true);
            return false;
        }

        return true;
    };

    const getBottomInputs = (): InputField[] => {
        const inputs: InputField[] = [];

        // Only show audit log for existing investments and admin users
        if (state.investment?._id && UserPermissions().access.admin) {
            inputs.push({
                type: 'custom',
                title: 'Audit Log',
                onChange: () => {},
                component: (
                    <EditExternalIds
                        userId={(state.investment?.user as any)?._id ?? state.investment?.user}
                        loaded={loaded}
                        loading={loading}
                        setAlert={setAlert}
                        externalIdsObject={state.investment.external_ids || {}}
                        saveExternalIds={async (externalIds) => {
                            if (!state.investment) {
                                console.error('no investment set');
                                return;
                            }
                            loading(0, 'setInvestmentExternalIds');
                            try {
                                await api2.client.InvestmentApi.updateInvestment({
                                    investment_id: state.investment._id,
                                    UpdateInvestmentRequest: {
                                        external_ids: externalIds,
                                    },
                                });
                                updateState({
                                    investment: {
                                        ...state.investment,
                                        external_ids: externalIds,
                                    },
                                });
                            } catch (err) {
                                console.error('Error saving investment ids', err);
                            }
                            loaded('setInvestmentExternalIds');
                        }}
                    />
                ),
            });
            inputs.push({
                type: 'custom',
                title: 'Audit Log',
                onChange: () => {}, // Required by interface
                component: (
                    <div style={{ marginTop: '20px' }}>
                        <AuditLogDisplay
                            fetchLogs={async () => {
                                try {
                                    const investment = (
                                        await api2.client.InvestmentApi.getInvestment({
                                            investment_id: state.investment?._id || '',
                                            populate_audit_log: true,
                                        })
                                    ).data.investment;
                                    return (investment?.audit_log ?? []).map((log: any) => ({
                                        _id: log._id,
                                        timestamp: log.timestamp,
                                        action: log.action,
                                        initiator_type: log.initiator_type,
                                        initiator: log.initiator,
                                        action_details: log.action_details,
                                    }));
                                } catch (err) {
                                    console.error('Error loading audit logs:', err);
                                    return [];
                                }
                            }}
                            containerStyle={{ margin: 0 }}
                        />
                    </div>
                ),
            });
        }
        return inputs;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        loading(0, 'investmentDrawer');

        try {
            const submissionObj: any = {
                _id: state.investment?._id,
                name: state.name,
                type: state.investment_type,
                inactive: state.inactive === true,
            };

            if (state.expected_tax_documents !== undefined && state.expected_tax_documents !== null) {
                submissionObj.expected_tax_documents = state.expected_tax_documents;
            }

            if (state.tax_document) {
                submissionObj.tax_document = state.tax_document;
            }

            if (state.editingInvestmentEntity?._id) {
                submissionObj.account = state.editingInvestmentEntity?._id;
            }

            if (state.editingConnection) {
                submissionObj.connection = state.editingConnection?._id ?? state.editingConnection;
            } else if (state.editingConnection === null) {
                submissionObj.connection = null;
            }

            if (state.investment_currency) {
                submissionObj.currency = state.investment_currency;
            }

            const investment_data = {
                ...state.investment?.investment_data,
                contraAccountNumber: state.contraAccountNumber,
            };

            const attributes = {
                ...state.investment?.attributes,
                addepar_loa_status_workflow_id: state.addepar_loa_status_workflow_id,
                addepar_portal_status_workflow_id: state.addepar_portal_status_workflow_id,
            };

            if (state.investment_subtype) {
                investment_data.type = state.investment_subtype;
            }

            submissionObj.investment_data = investment_data;
            submissionObj.attributes = attributes;

            if (state.inactive_date) {
                submissionObj.inactive_date = state.inactive_date;
            }

            if (onSubmit) {
                await onSubmit(state.investment, submissionObj);
            }

            reloadData();
            closeDrawer();
        } catch (error) {
            console.error('Error submitting investment:', error);
            setDismissableAlert(setAlert, 'Failed to update investment', true, 7000);
        } finally {
            loaded('investmentDrawer');
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this investment?')) return;

        if (!state.investment?._id) {
            setDismissableAlert(setAlert, 'Could not delete investment', true);
            return;
        }

        loading(320, 'investmentDrawer');

        try {
            await api2.client.InvestmentApi.deleteInvestment({
                investment_id: state.investment._id,
            });
            setDismissableAlert(setAlert, 'Investment deleted successfully', false);
            reloadData();
            closeDrawer();
        } catch (error) {
            console.error('Error deleting investment:', error);
            setDismissableAlert(setAlert, 'Could not delete investment', true);
        } finally {
            loaded('investmentDrawer');
        }
    };

    const getInputs = (): InputField[] => {
        const investment = state.investment;
        if (!investment) return [];
        console.log('state', state);

        const inputs: InputField[] = [
            {
                title: 'Investment Name',
                alt: 'the name of the investment',
                defaultValue: state.name ?? '',
                onChange: (val: string | number | { title: string }) => updateState({ name: String(val) }),
                type: 'text',
            },
            {
                title: 'Investment Entity',
                type: 'custom',
                component: (
                    <>
                        <label>Investment Entity</label>
                        <AccountSearchFilter
                            defaultLabel={'Select Investment Entity'}
                            filter={{
                                users: [relevantUser?._id],
                            }}
                            selected={state.editingInvestmentEntity || investment.account}
                            onChange={(editingInvestmentEntity) => {
                                if (!editingInvestmentEntity) return;
                                updateState({ editingInvestmentEntity });
                            }}
                            defaultOptions={true}
                            excludeDisplayFields={['user']}
                        />
                    </>
                ),
                onChange: () => {}, // Required by interface
            },
            {
                title: 'Connection',
                type: 'custom',
                component: (
                    <>
                        <label>Connection</label>
                        <ConnectionSearchFilter
                            defaultLabel={'Select Connection'}
                            selected={state.editingConnection !== undefined ? state.editingConnection : investment.connection}
                            onChange={(editingConnection) => {
                                updateState({ editingConnection });
                            }}
                            filter={{
                                users: [null, relevantUser?._id],
                                ...(UserPermissions().access.admin
                                    ? {
                                          include_userless: true,
                                      }
                                    : {}),
                            }}
                            defaultOptions={true}
                        />
                    </>
                ),
                onChange: () => {}, // Required by interface
            },
            {
                title: 'Investment Type',
                options: state.typeOptions,
                defaultValue: { title: state.investment_type },
                type: 'dropdown',
                onChange: (val: string | number | { title: string }) => {
                    if (typeof val === 'object' && 'title' in val) {
                        const newType = val.title;
                        updateState({ investment_type: newType });
                        const subtypeOptions = getInvestmentSubtypes(newType);
                        updateState({ investment_subtype: subtypeOptions?.[0] });
                    }
                },
                excludeNull: true,
            },
            {
                title: 'Investment Subtype',
                options: getInvestmentSubtypes(state.investment_type).map((st: string) => ({ title: st })),
                defaultValue: { title: state.investment_subtype },
                type: 'dropdown',
                onChange: (val: string | number | { title: string }) => {
                    if (typeof val === 'object' && 'title' in val) {
                        updateState({ investment_subtype: val.title });
                    }
                },
                excludeNull: true,
            },
            {
                title: 'Currency',
                alt: 'the currency of the investment',
                value: Object.values(CURRENCIES)
                    .map((c) => ({
                        title: getCurrencyString(c as Currency),
                        value: c,
                    }))
                    .find((c) => c.title === getCurrencyString((state.investment_currency as Currency) ?? CURRENCIES.USD)),
                options: Object.values(CURRENCIES).map((c) => ({
                    title: getCurrencyString(c as Currency),
                    value: c,
                })),
                type: 'dropdown',
                onChange: (val: string | number | { title: string }) => {
                    if (typeof val === 'object' && 'value' in val && typeof val.value === 'string') {
                        const newCurrency = val.value;
                        updateState({ investment_currency: newCurrency });
                    }
                },
                excludeNull: true,
            },
            {
                title: 'Contra Account ID',
                alt: 'ID of the Contra Account',
                defaultValue: state.contraAccountNumber ?? '',
                onChange: (val: string | number | { title: string }) => updateState({ contraAccountNumber: String(val) }),
                type: 'text',
            },
            {
                title: 'Addepar LOA Status Workflow ID',
                alt: 'Addepar LOA Status Workflow ID',
                defaultValue: state.addepar_loa_status_workflow_id ?? '',
                onChange: (val: string | number | { title: string }) => updateState({ addepar_loa_status_workflow_id: String(val) }),
                type: 'text',
            },
            {
                title: 'Addepar Portal Status Workflow ID',
                alt: 'Addepar Portal Status Workflow ID',
                defaultValue: state.addepar_portal_status_workflow_id ?? '',
                onChange: (val: string | number | { title: string }) => updateState({ addepar_portal_status_workflow_id: String(val) }),
                type: 'text',
            },
            {
                title: 'Tax Documents Expected',
                type: 'toggle',
                options: [
                    { title: 'No', value: '0' },
                    { title: 'Yes', value: '1' },
                ],
                value: state.expected_tax_documents ? 1 : 0,
                onChange: (val: string | number | { title: string }) =>
                    updateState({
                        expected_tax_documents: Boolean(Number(val)),
                    }),
            },
        ];

        // Add inactive toggle at the end
        inputs.push({
            type: 'custom',
            title: 'Investment Status',
            component: (
                <div style={{ zIndex: 0 }}>
                    <InvestmentInactiveToggle
                        containerStyle={{
                            height: '25px',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                        investment={{
                            ...investment,
                            inactive: state.inactive ?? investment.inactive,
                            inactive_date: state.inactive || investment.inactive ? (state.inactive_date ?? investment.inactive_date ?? undefined) : undefined,
                        }}
                        loading={loading}
                        loaded={loaded}
                        setAlert={(message: string, isError?: boolean) => setAlert(message, isError ?? false)}
                        onChange={(newValue) => {
                            if (newValue === state.inactive) return;
                            updateState({
                                inactive: newValue,
                                inactive_date: newValue ? new Date().toISOString() : undefined,
                            });
                        }}
                        onChangeDate={(newValue) => {
                            updateState({ inactive_date: newValue ? new Date().toISOString() : undefined });
                        }}
                        allowEditDate={true}
                    />
                </div>
            ),
            onChange: () => {}, // Required by interface
        });

        // Add hidden toggle for admins
        if (UserPermissions().access.admin) {
            inputs.push({
                type: 'custom',
                title: 'Investment Visibility',
                component: (
                    <div style={{ zIndex: 0 }}>
                        <InvestmentHiddenToggle
                            containerStyle={{
                                height: '25px',
                                marginTop: '25px',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                display: 'flex',
                            }}
                            investment={{
                                ...investment,
                                hidden: state.hidden ?? investment.hidden,
                            }}
                            loading={loading}
                            loaded={loaded}
                            setAlert={(message: string, isError?: boolean) => setAlert(message, isError ?? false)}
                            onChange={(newValue) => {
                                if (newValue === state.hidden) return;
                                updateState({ hidden: newValue });
                            }}
                        />
                    </div>
                ),
                onChange: () => {}, // Required by interface
            });
        }

        return inputs;
    };

    const permissions = UserPermissions();
    if (!permissions.canReadAnyInvestments) {
        return (
            <div className="a" style={{ padding: '20px', cursor: 'pointer' }} onClick={closeDrawer}>
                Cancel
            </div>
        );
    }

    return (
        <div className="addNewAdvisorToFirm-drawer" data-testid="investment-drawer">
            <div style={{ position: 'relative' }}>
                <DrawerContent
                    idPrefix="investment"
                    title={state.investment?._id ? 'Edit Investment' : 'Create Investment'}
                    inputs={getInputs()}
                    bottomInputs={getBottomInputs()}
                    submitDisabled={false}
                    onSubmit={handleSubmit}
                    onCancel={closeDrawer}
                    onDelete={state.investment?._id ? handleDelete : undefined}
                    readOnly={!permissions.canUpdateAnyInvestments}
                />
            </div>
            <div style={{ clear: 'both', height: '100px' }} />
        </div>
    );
};

export default React.memo(EditInvestmentDrawerContent);
