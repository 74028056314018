import axios from 'axios';
import pako from 'pako';
import React from 'react';
import Page from './Page';
import Toolbar from './Toolbar';

import _ from 'lodash';
import FloatingButton from '../../components/Buttons/FloatingButton';
import '../../styles/DocumentViewer.css';
import DocumentViewerMenu from './DocumentViewerMenu';
import { DEFAULT_HIGHLIGHT_COLOR, doWordsMatch } from './helpers';

export const WORD_ACTIVITY_TYPES = {
    HOVERED: 'hovered',
    SELECTED: 'selected',
    HIGHLIGHTED: 'highlighted',
};

class DocumentViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documentData: {
                id: null,
                allWords: [],
                pageWords: [],
                pageImages: [],
            },
            documentSearchParams: '',
            documentSearchResults: {},
            totalSearchResults: 0,
            userTextSelectionByIndex: {
                start: {
                    pageNumber: -1,
                    wordIndex: 0,
                },
                end: {
                    pageNumber: -1,
                    wordIndex: 0,
                },
            },
            userTextSelectionByText: '',
            pageSizeStyle: '85%',

            mouse: { x: -1, y: -1 },
            menuPosition: null,
        };
    }

    componentDidMount() {
        this.loadData();
        this.initIntersectionObserver();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.documentSearchResults !== this.state.documentSearchResults) {
            let totalSearchResults = 0;
            for (const key in this.state.documentSearchResults) {
                const results = [...this.state.documentSearchResults[key]];
                totalSearchResults += results.length;
            }
            this.deselectAllWords();
            this.setState(() => ({ totalSearchResults }));
        }
        if (!_.isEqual(prevProps.doc?.file_download_urls?.pages, this.props.doc?.file_download_urls?.pages)) {
            this.loadData();
        }
        if (!_.isEqual(prevProps.highlightedWordsObjList, this.props.highlightedWordsObjList)) {
            this.handleHighlightedWords();
        }
    }

    loadData = async () => {
        this.props.loading(0, 'docViewer_loadData');
        const { doc } = this.props;
        const initialPageImages = new Array(doc.file_download_urls.pages.length).fill(null);
        await this.setState({
            documentData: {
                id: doc?._id,
                allWords: [],
                pageWords: [],
                pageImages: initialPageImages,
            },
        });
        await this.fetchPageWords();
        // load the first 3 pages
        this.props.loaded('docViewer_loadData');
        await this.ensurePageAndNeighbors(0);
    };

    initIntersectionObserver() {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '0px',
            threshold: 0.1, // trigger when 10% of the target is visible
        };

        this.observer = new IntersectionObserver(this.handleIntersection, options);
    }

    handleIntersection = async (entries) => {
        entries.forEach(async (entry) => {
            const pageIndex = parseInt(entry.target.getAttribute('data-page-index'));
            if (entry.isIntersecting) {
                await this.ensurePageAndNeighbors(pageIndex);
            } else {
                await this.unloadDistantPages(pageIndex);
            }
        });
    };

    DISTANT_PAGE_COUNT = 24;
    NEIGHBOR_PAGE_COUNT = 8;

    unloadDistantPages(currentIndex) {
        const start = Math.max(0, currentIndex - this.DISTANT_PAGE_COUNT);
        const end = Math.min(this.props?.file_download_urls?.pages.length, currentIndex + this.DISTANT_PAGE_COUNT);
        this.setState((prevState) => {
            const pageImages = [...prevState.documentData.pageImages];
            for (let i = 0; i < start; i++) {
                pageImages[i] = null;
            }
            for (let i = end; i < this.props?.file_download_urls?.pages.length; i++) {
                pageImages[i] = null;
            }
            return {
                documentData: {
                    ...prevState.documentData,
                    pageImages,
                },
            };
        });
    }

    async updatePageDataInState(pageIndex, pageImage, documentId = null, ensurePreviouslyEmpty = true) {
        let updated = false;
        await this.setState((prevState) => {
            if (documentId && documentId !== prevState?.documentData?.id) {
                console.log('document changed, updatePageDataInState exited');
                return null;
            }
            const pageImages = [...prevState.documentData.pageImages];
            if (ensurePreviouslyEmpty && pageImages[pageIndex] !== null) {
                // console.log("page", pageIndex, "already", pageImages[pageIndex] === false ? "loading" : "loaded", "updatePageDataInState exited")
                return null;
            }
            pageImages[pageIndex] = pageImage;
            updated = true;
            return {
                documentData: {
                    ...prevState.documentData,
                    pageImages,
                },
            };
        });
        return updated;
    }

    async ensurePageAndNeighbors(pageIndex) {
        const start = Math.max(0, pageIndex - this.NEIGHBOR_PAGE_COUNT);
        const end = Math.min(this.props.doc.file_download_urls?.pages.length, pageIndex + this.NEIGHBOR_PAGE_COUNT);
        // for (let i = start; i < end; i++) {
        //     console.log('ensurePageAndNeighbors', pageIndex, start, end);
        //     await this.ensurePageImage(i, this.props.doc._id);
        // }
        await Promise.all(
            [...Array(end - start).keys()].map(async (i) => {
                await this.ensurePageImage(i + start, this.props.doc._id);
            })
        );
    }

    async ensurePageImage(pageIndex, currentDocumentId) {
        // console.log('startEnsurePageImage', pageIndex, currentDocumentId, new Date().getTime())
        // Atomically claim to be the loader of this page
        const updateResult = await this.updatePageDataInState(pageIndex, false, currentDocumentId, true);
        if (!updateResult) {
            return;
        }

        const pageImage = await this.downloadPageImage(this.props.doc?.file_download_urls?.pages[pageIndex]);
        // console.log('downloaded page', pageIndex, new Date().getTime())
        if (!pageImage) {
            // atomically undo the claim
            await this.updatePageDataInState(pageIndex, null, currentDocumentId, false);
            return;
        }
        await this.updatePageDataInState(pageIndex, pageImage, currentDocumentId, false);
        // console.log('endEnsurePageImage', pageIndex, currentDocumentId, new Date().getTime())
    }

    async downloadPageImage(page) {
        try {
            const res = await this.download(page);
            // Assuming the response data is in the correct format, you can directly create a Blob
            const blob = new Blob([res.data], { type: 'image/jpeg' });
            return URL.createObjectURL(blob);
        } catch (err) {
            console.log(err);
            return null; // or any default image URL in case of an error
        }
    }

    async fetchPageWords() {
        const jsonData = await this.downloadAndUnzip(this.props.doc.file_download_urls.words);

        const allWords = jsonData.map((wordData) => ({
            x: wordData.x,
            y: wordData.y,
            width: wordData.width,
            height: wordData.height,
            angle: wordData.angle,
            page: wordData.page,
            group: wordData.group,
            line: wordData.line,
            word: wordData.word,
            class: wordData.class,
            text: wordData.text,
        }));

        const pageWords = this.words2PageWords(allWords);

        // console.log('pages2', pages2.length, pages.length, _.isEqual(pages, pages2))
        this.setState({
            documentData: {
                ...this.state.documentData,
                allWords,
                pageWords,
            },
        });
    }

    downloadDocumentHandler = () => {
        window.open(this.props.doc.file_download_urls.doc, '_blank');
    };

    copyEventHandler = (event) => {
        console.log('copyEventHandler');
        event.preventDefault();
        event.clipboardData.setData('text/plain', this.state.userTextSelectionByText);
    };

    async download(url) {
        try {
            const res = await axios({
                method: 'get',
                url: url,
                responseType: 'blob',
            });
            return res;
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Cancelled request:', err.message);
            } else {
                console.log(err);
            }
        }
    }

    async downloadAndUnzip(url) {
        try {
            const res = await axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
            });

            const decompressedData = pako.ungzip(res.data, { to: 'string' });
            const jsonData = JSON.parse(decompressedData);
            return jsonData;
        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Cancelled request:', err.message);
            } else {
                throw err;
            }
        }
    }

    words2PageWords = (words, wordMutator = () => {}) => {
        const pageWords = words.reduce((acc, word, wordIndex, wordArray) => {
            // word = wordMutator(word);
            wordMutator(word, wordIndex, wordArray);
            if (word.page > acc.length - 1) {
                acc.push([word]);
            } else {
                acc[word.page].push(word);
            }
            return acc;
        }, []);
        return pageWords;
    };

    deselectAllWords = () => {
        const pageWords = this.words2PageWords(this.state.documentData.allWords, (word) => {
            word.selected = false;
        });
        this.setState((prevState) => ({ documentData: { ...prevState.documentData, pageWords } }));
        this.props.setSelectedWords([], '');
    };

    // gets word index in allWords array
    // meaning from the beginning of the entire document
    getAllWordsIndex = (pageNumber, wordIndex) => {
        const allWordsIndex = [].concat(...[...this.state.documentData.pageWords].splice(0, 0 + pageNumber)).length + wordIndex;
        return allWordsIndex;
    };

    handleHighlightedWords = () => {
        const { highlightedWordsObjList } = this.props;
        const pageWords = this.words2PageWords(this.state.documentData.allWords, (word) => {
            // highlightedWordsObjList is a list of objects,
            // each has a words list that could have a match
            // flatten the words list and check if any match
            const match = highlightedWordsObjList.find((obj) => {
                return obj.words.some((highlightedWord) => doWordsMatch(highlightedWord, word));
            });
            word.highlighted = !!match;
            word.highlightedColor = match?.color ?? DEFAULT_HIGHLIGHT_COLOR;
        });
        this.setState((prevState) => ({ documentData: { ...prevState.documentData, pageWords } }));
    };

    handleUserTextSelection = () => {
        const { start, end } = this.state.userTextSelectionByIndex;

        // console.log('handleUserTextSelection', start, end);
        if ([start.pageNumber, end.pageNumber].includes(-1)) {
            this.props.setSelectedWords([], '');
            return;
        }

        // get start and end index of selected words
        let startIndex = this.getAllWordsIndex(start.pageNumber, start.wordIndex);
        let endIndex = this.getAllWordsIndex(end.pageNumber, end.wordIndex);

        // swap start and end index if start index is greater than end index
        if (startIndex > endIndex) {
            const temp = startIndex;
            startIndex = endIndex;
            endIndex = temp;
        }

        const selectedWords = [];

        // select words in selected range, and deselect all other words
        // also build selectionString
        let selectionString = '';
        const pageWords = this.words2PageWords(this.state.documentData.allWords, (word, wordIndex) => {
            if (wordIndex >= startIndex && wordIndex <= endIndex) {
                word.selected = true;
                selectionString += word.text + ' ';
                selectedWords.push(word);
            } else word.selected = false;
        });

        selectionString = selectionString.trim();

        this.props.setSelectedWords(selectedWords, selectionString);

        this.setState((prevState) => ({
            userTextSelectionByText: selectionString,
            documentData: { ...prevState.documentData, pageWords },
        }));
    };

    setMousePosition = (x, y) => {
        this.setState((prevState) => ({
            mouse: { ...prevState.mouse, x, y },
        }));
    };

    getDocContainerRect = () => {
        const docContainer = document.querySelector('.docContainer');
        return docContainer?.getBoundingClientRect();
    };

    renderFloatingButtons = () => {
        const BUTTON_SPACING = 10;
        return (
            <div style={{ zIndex: 2 }}>
                {/* Previous doc button */}
                {this.props.goToPreviousDoc && this.props.previousAndNextDocIds?.previous && (
                    <FloatingButton
                        // children={'<'}
                        containerStyle={{
                            position: 'absolute',
                            left: BUTTON_SPACING,
                            top: '50%',
                        }}
                        buttonStyle={{
                            fontSize: '2.5rem',
                        }}
                        onClick={this.props.goToPreviousDoc}
                        // disabled={this.state.isLoadingPages}
                    >{`<`}</FloatingButton>
                )}
                {/* Next doc button */}
                {this.props.goToNextDoc && this.props.previousAndNextDocIds?.next && (
                    <FloatingButton
                        // children={'>'}
                        containerStyle={{
                            position: 'absolute',
                            right: BUTTON_SPACING,
                            top: '50%',
                        }}
                        buttonStyle={{
                            fontSize: '2.5rem',
                        }}
                        onClick={this.props.goToNextDoc}
                        // disabled={this.state.isLoadingPages}
                    >{`>`}</FloatingButton>
                )}
            </div>
        );
    };

    // // puts "loading..." at the bottom of the page
    // renderLoading = () => {
    //     if (!this.state.isLoadingPages) return null;
    //     return <div
    //         style={{
    //             position: 'absolute',
    //             bottom: '5px',
    //             fontWeight: 'bold',
    //             fontSize: '1.5rem',
    //         }}
    //     >Loading.....</div>
    // }

    render() {
        const s = this.state;
        const p = this.props;
        // const {
        // documentData,
        // documentSearchParams,
        // documentSearchResults,
        // totalSearchResults,
        // pageSizeStyle,
        // userTextSelectionByIndex,
        // menuPosition,
        // } = this.state;

        return (
            <div
                className="docContainer"
                onCopy={this.copyEventHandler}
                onMouseMove={(e) => this.setMousePosition(e.clientX, e.clientY)}
                onMouseLeave={() => this.setMousePosition(-1, -1)}
            >
                {/* Pages List */}
                {s.documentData.pageImages.map((page, index) =>
                    page === false ? (
                        <div className="pageLoading" key={`pageLoading_${index}`}>
                            Loading...
                        </div>
                    ) : page ? (
                        <div
                            key={`pageContainer_${index}`}
                            className="pageContainer"
                            data-page-index={index}
                            ref={(node) => {
                                if (node) {
                                    this.observer.observe(node);
                                }
                            }}
                        >
                            <Page
                                page={page}
                                words={s.documentData.pageWords[index]}
                                documentSearchParams={s.documentSearchParams}
                                setDocumentSearchResults={(documentSearchResultsUpdate) => {
                                    this.setState((prevState) => ({
                                        documentSearchResults: documentSearchResultsUpdate(prevState.documentSearchResults),
                                    }));
                                }}
                                documentSearchResults={s.documentSearchResults}
                                style={{ width: s.pageSizeStyle }}
                                deselectAllWords={this.deselectAllWords}
                                setUserTextSelection={(userTextSelectionByIndexUpdate) => {
                                    this.setState(
                                        (prevState) => ({
                                            userTextSelectionByIndex: userTextSelectionByIndexUpdate(prevState.userTextSelectionByIndex),
                                        }),
                                        this.handleUserTextSelection
                                    );
                                }}
                                userTextSelection={s.userTextSelectionByIndex}
                                pageNumber={index}
                                key={index}
                                onContextMenu={this.props.onContextMenu}
                                setHoveredWord={this.props.setHoveredWord}
                                hoveredWord={this.props.hoveredWord}
                            />
                        </div>
                    ) : null
                )}
                {/* Toolbar */}
                <Toolbar
                    documentNumber={p.documentNumber}
                    totalDocuments={p.totalDocuments}
                    setDocumentSearchParams={(documentSearchParamsUpdate) => {
                        this.setState(() => ({
                            documentSearchParams: documentSearchParamsUpdate,
                        }));
                    }}
                    downloadDocument={this.downloadDocumentHandler}
                    setPageSizeStyle={(pageSizeStyleUpdate) => {
                        console.log(pageSizeStyleUpdate);
                        this.setState((prevState) => ({
                            pageSizeStyle: pageSizeStyleUpdate(prevState.pageSizeStyle),
                        }));
                    }}
                    documentSearchResults={s.documentSearchResults}
                    setDocumentSearchResults={(documentSearchResultsUpdate) => {
                        this.setState((prevState) => ({
                            documentSearchResults: documentSearchResultsUpdate(prevState.documentSearchResults),
                        }));
                    }}
                    totalSearchResults={s.totalSearchResults}
                />
                {/* Context Menu */}
                {s.menuPosition && <DocumentViewerMenu top={s.menuPosition.y} left={s.menuPosition.x} />}
                {/* Prev/Next/Close buttons */}
                {this.renderFloatingButtons()}
                {/* {this.renderLoading()} */}
            </div>
        );
    }
}

export default DocumentViewer;
