import { useState } from 'react';

import api2 from '../../../api2';
import { InvestmentMasterSearchFilter } from '../../../components/Filters/GenericSearchFilter';

interface InvestmentMasterAttacherProps {
    investmentIds: string[];
    filter?: any;
    title?: string;
    onSubmit?: (investmentMasterId: string, errorMessage?: string) => Promise<void>;
    onClose: () => void;
    loading?: (left: number, loadingId: string) => void;
    loaded?: (loadingId: string) => void;
}

const InvestmentMasterAttacher = ({ investmentIds, filter = {}, title = 'Attach Investment Master', onSubmit, onClose, loading, loaded }: InvestmentMasterAttacherProps) => {
    const [investmentMasterFilter, setInvestmentMasterFilter] = useState<any>(null);

    const attachInvestmentMaster = async () => {
        const investment_master_id = investmentMasterFilter?._id;

        const results = await Promise.all(
            investmentIds.map(async (investment_id) => {
                try {
                    const success = (
                        await api2.client.InvestmentApi.updateInvestment({
                            investment_id,
                            UpdateInvestmentRequest: {
                                investment_master: investment_master_id,
                            },
                        })
                    ).data.success;
                    return success ?? false;
                } catch (error) {
                    return false;
                }
            })
        );

        if (results.some((result: boolean) => result !== true)) {
            await onSubmit?.(investmentMasterFilter, 'Error attaching investment master');
        } else {
            await onSubmit?.(investmentMasterFilter);
        }
    };

    const submit = async () => {
        if (!investmentMasterFilter?._id) return;
        // attach investment master to investments
        loading?.(0, 'attachingInvestmentMaster');
        await attachInvestmentMaster();
        loaded?.('attachingInvestmentMaster');
        onClose();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
            <div style={{ fontSize: '22px', fontWeight: 'bold', color: 'var(--color-primary)' }}>{title}</div>
            <p>to {investmentIds.length} investments</p>
            <InvestmentMasterSearchFilter
                filter={filter}
                selected={investmentMasterFilter}
                onChange={(newInvestmentMasterFilter) => {
                    setInvestmentMasterFilter(newInvestmentMasterFilter);
                }}
                defaultLabel={'All Investment Masters'}
                defaultOptions={true}
                width={'350px'}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '350px', gap: '40px' }}>
                <button style={{ margin: 0 }} onClick={onClose}>
                    Cancel
                </button>
                <button style={{ margin: 0 }} onClick={submit} disabled={!investmentMasterFilter?._id}>
                    Submit
                </button>
            </div>
        </div>
    );
};

export default InvestmentMasterAttacher;
