import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCurrencyString } from '../../../constants/currencyConstants';
import '../../../styles/investmentCashFlow.css';
import '../../../styles/widget.css';
import { Currency } from '../../../types';

const isMobile = () => window.innerWidth <= 768;

const DISTRIBUTIONS_COLOR = '#009F9B';
const CONTRIBUTIONS_COLOR = '#36637d';
const TOTAL_COLOR = '#B64040';

const getLegendKeys = (currency = Currency.USD) => {
    return [
        {
            label: `Paid-in Capital (${getCurrencyString(currency)})`,
            key: `contributions`,
            color: CONTRIBUTIONS_COLOR,
            sign: 1,
        },
        {
            label: `Total Distributions (${getCurrencyString(currency)})`,
            key: `distributions`,
            color: DISTRIBUTIONS_COLOR,
            sign: 1,
        },
    ];
};

interface Investment {
    _id: string;
    performance?: {
        monthly?: Array<{
            year: number;
            month: number;
            snapshot?: {
                contributions?: number;
                distributions?: number;
            };
            valuation?: number;
        }>;
    };
}

export interface InvestmentCashFlow2Props extends RouteComponentProps {
    investments: Investment[];
    currency?: Currency;
    fontSize?: number;
}
console.log('test');
const InvestmentCashFlow2: React.FC<InvestmentCashFlow2Props> = (props) => {
    const [data, setData] = useState<any>(null);

    const prevInvestmentsRef = useRef<Investment[] | null>(null);

    const getCurrency = () => props.currency ?? Currency.USD;

    const getsNumWithSign = (num: number, sign: number) => (num === 0 ? 0 : Math.abs(num) * sign);

    const getCashflow = (investments: Investment[]) => {
        if (!investments || investments.length === 0) return [];

        let monthlyList = investments.reduce((acc: any[], i) => (i.performance?.monthly ? acc.concat(i.performance.monthly.map((m) => ({ ...m, investmentId: i._id }))) : acc), []);

        const minYear = Math.min(...monthlyList.map((m) => m.year));
        const maxYear = Math.max(...monthlyList.map((m) => m.year));

        let cashflowRes: Record<number, any> = {};
        let cumulativeDistributions = 0;
        for (let year = minYear; year <= maxYear; year++) {
            const curMonthly = monthlyList.filter((m) => m.year === year);

            const yearlyCashflow = {
                contributions: 0,
                distributions: 0,
                total: 0,
                year,
            };

            curMonthly.forEach((m) => {
                yearlyCashflow.contributions += m.snapshot?.contributions ? Math.abs(m.snapshot?.contributions) : 0;
                yearlyCashflow.distributions += m.snapshot?.distributions ? Math.abs(m.snapshot?.distributions) : 0;
            });

            const investmentIds = curMonthly.map((m) => m.investmentId).filter((v, i, a) => a.indexOf(v) === i);
            investmentIds.forEach((id) => {
                const invMonthly = curMonthly.filter((m) => m.investmentId === id);
                yearlyCashflow.total += invMonthly?.[invMonthly.length - 1]?.valuation ?? 0;
            });

            cumulativeDistributions += yearlyCashflow.distributions;
            yearlyCashflow.total += cumulativeDistributions;

            cashflowRes[year] = yearlyCashflow;
        }
        return Object.values(cashflowRes).sort((a, b) => a.year - b.year);
    };

    const loadData = useCallback(() => {
        const p = props;

        if (!p.investments || !p.investments.length) return;

        const cashflow = getCashflow(p.investments);

        const lineData = {
            type: 'line',
            label: `Total Value (${getCurrencyString(getCurrency())})`,
            backgroundColor: TOTAL_COLOR,
            borderColor: TOTAL_COLOR,
            borderWidth: 3,
            data: cashflow.map((item) => item.total),
        };

        const barData = getLegendKeys(getCurrency()).map((key) => {
            return {
                type: 'bar',
                label: key.label,
                backgroundColor: key.color + 'd9',
                borderColor: key.color,
                borderWidth: 2,
                barThickness: isMobile() ? 8 : 30,
                data: cashflow.map((item) => getsNumWithSign(item[key.key], 1)),
            };
        });

        const datasets = [lineData, ...barData];
        const labels = cashflow.map((c) => c.year);

        const data = { labels, datasets };

        setData(data);
    }, [props]);

    useEffect(() => {
        if (!_.isEqual(prevInvestmentsRef.current, props.investments)) {
            loadData();
            prevInvestmentsRef.current = props.investments;
        }
    }, [props.investments, loadData]);

    const getChartOptions = (data: any) => ({
        animation: { duration: 0 },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: props.fontSize ?? 16,
                    },
                },
            },
            y: {
                stacked: false,
                grid: {
                    display: true,
                },
                ticks: {
                    font: {
                        size: props.fontSize ?? 16,
                    },
                },
            },
        },
        interaction: {
            mode: 'index' as const,
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
            },
            line: {
                borderColor: '#969696',
                borderWidth: 1,
            },
        },
    });

    const renderLegendKeys = () => {
        return (
            <div className="cashFlowLegend">
                <div className="cashFlowLegendItem" key={`legendKey${-1}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        className="cashFlowLegendCircle2"
                        style={{
                            borderBottom: `3px solid ${TOTAL_COLOR}`,
                            width: '20px',
                            marginRight: '5px',
                        }}
                    />
                    <div className="cashFlowLegendText">Total Value ({getCurrencyString(getCurrency())})</div>
                </div>
                {getLegendKeys(getCurrency()).map((elem, idx) => (
                    <div className="cashFlowLegendItem" key={`legendKey${idx}`}>
                        <div className="cashFlowLegendCircle" style={{ backgroundColor: elem.color }} />
                        <div className="cashFlowLegendText">{elem.label}</div>
                    </div>
                ))}
            </div>
        );
    };

    if (!data) return null;
    return (
        <div className="widget cashFlowComponent">
            <div className="widget-header">
                Cash Flow
                {renderLegendKeys()}
            </div>

            {data && (
                <div className="cashFlowGraph">
                    <Chart type="bar" data={data} options={getChartOptions(data)} style={{ position: 'relative', backgroundColor: 'transparent' }} height={400} />
                </div>
            )}
        </div>
    );
};

export default withRouter(InvestmentCashFlow2);
