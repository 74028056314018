import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import '../../styles/addConnectionDrawer.css';
import { renderInputComponentList } from '../DrawerComponents/renderInputComponentList';
import renderInputError from '../renderInputError';

export interface GenericDrawerProps extends RouteComponentProps {
    title: string;
    actionStr?: string;
    loadingKey?: string;
    getFields?: (state: any, setState: any) => Record<string, any>;
    onSubmit?: (data: any) => Promise<boolean | void>;
    onDelete?: () => Promise<void>;
    close: () => void;
    loading?: (time?: number, key?: string) => void;
    loaded?: (key?: string) => void;
    loadState?: () => Promise<any>;
    loadData?: () => Promise<any>;
    readOnly?: boolean;
    extraContent?: () => React.ReactNode;
    className?: string;
    errors?: Record<string, string>;
    setErrors?: (errors: Record<string, string>) => void;
    isSafeToSave?: (isSafe: boolean, formData: any) => boolean;
    children?: React.ReactNode;
    onFieldChange?: (fieldName: string, value: any) => void;
    formData?: Record<string, any>;
    renderHeader?: () => React.ReactNode;
}

interface DrawerState {
    commonData: Record<string, any>;
    confirmDelete: boolean;
    isLoading: boolean;
}

const GenericDrawer: React.FC<GenericDrawerProps> = ({
    title,
    actionStr = 'Submit',
    loadingKey = 'GenericDrawer',
    getFields,
    onSubmit,
    onDelete,
    close,
    loading,
    loaded,
    loadState,
    loadData,
    readOnly = false,
    extraContent,
    className,
    errors: externalErrors = {},
    setErrors: setExternalErrors,
    children,
    isSafeToSave,
    onFieldChange,
    formData,
    renderHeader,
}) => {
    const [state, setState] = useState<DrawerState>({
        commonData: {},
        confirmDelete: false,
        isLoading: false,
    });
    const [localErrors, setLocalErrors] = useState<Record<string, string>>({});
    const mounted = useRef(true);
    const isLoadingRef = useRef(false);

    // Combine external and local errors
    const errors = { ...localErrors, ...externalErrors };

    // Add cleanup effect
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    // Update clearError to check mounted status
    const clearError = (keys: string[], updatedData?: Record<string, any>) => {
        const newErrors = { ...errors };
        keys.forEach((k) => delete newErrors[k]);

        if (isSafeToSave) {
            const dataToValidate = updatedData || state.commonData;
            const isValid = isSafeToSave(false, dataToValidate);
            newErrors.submit = isValid ? '' : 'There are missing required fields';
        }

        if (mounted.current) {
            setLocalErrors(newErrors);
            setExternalErrors?.(newErrors);
        }
    };

    const loadInitialData = async () => {
        if (isLoadingRef.current) {
            return;
        }

        try {
            isLoadingRef.current = true;
            loading?.(0, loadingKey);

            if (loadData) {
                const loadedData = await loadData();
                setState((prev) => ({
                    ...prev,
                    commonData: {
                        ...prev.commonData,
                        ...loadedData,
                    },
                }));
                if (loadState) {
                    const loadedState = await loadState();
                    if (mounted.current) {
                        setState((prev) => ({
                            ...prev,
                            commonData: {
                                ...prev.commonData,
                                ...loadedData,
                            },
                            ...(loadedState || {}),
                        }));
                    }
                }
            }
        } catch (error) {
            console.error('GenericDrawer: Error in loadInitialData:', error);
        } finally {
            if (mounted.current) {
                loaded?.(loadingKey);
                isLoadingRef.current = false;
            }
        }
    };

    useEffect(() => {
        loadInitialData();
        return () => {
            mounted.current = false;
        };
    }, []);

    // Update setStateValue to check mounted status
    const setStateValue = (key: string, value: any, stateField: keyof DrawerState = 'commonData') => {
        if (!mounted.current) return;

        setState((prev) => {
            const update = {
                ...prev,
                [stateField]: {
                    ...(prev[stateField] as object),
                    [key]: value,
                },
            };

            // Pass the updated data to clearError
            const updatedCommonData = {
                ...(prev[stateField] as object),
                [key]: value,
            };

            // Use setTimeout to ensure state is updated
            if (mounted.current) {
                setTimeout(() => {
                    if (mounted.current) {
                        clearError([key], updatedCommonData);
                    }
                }, 0);
            }

            return update;
        });
    };

    // Update handleSubmit to check mounted status
    const handleSubmit = async () => {
        if (!onSubmit) return;

        try {
            if (isSafeToSave && !isSafeToSave(true, state.commonData)) {
                throw new Error('Please fill in all required fields');
            }

            const result = await onSubmit(state.commonData);

            if (mounted.current) {
                if (result === true) {
                    close();
                }
            }
        } catch (error: any) {
            if (!mounted.current) return;

            const errorMessage = error.response?.data?.message || error.response?.data?.error || error.message || 'An unexpected error occurred';

            const newErrors = {
                ...errors,
                submit: errorMessage,
            };
            setLocalErrors(newErrors);
        }
    };

    const handleDelete = async () => {
        if (!state.confirmDelete || !onDelete) return;

        try {
            await onDelete();
            close();
        } catch (error: any) {
            setExternalErrors?.({
                ...externalErrors,
                delete: error.message || 'Failed to delete',
            });
        }
    };

    const handleFieldChange = (key: string | number, value: any) => {
        const fieldName = key.toString();
        if (onFieldChange) {
            onFieldChange(fieldName, value);
        }
        setStateValue(fieldName, value, 'commonData');
    };

    useEffect(() => {
        const handleResizeError = (e: ErrorEvent) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                e.stopImmediatePropagation();
                e.stopImmediatePropagation();
            }
        };

        window.addEventListener('error', handleResizeError);

        return () => {
            window.removeEventListener('error', handleResizeError);
        };
    }, []);

    return (
        <>
            <div className={className} data-testid="generic-drawer">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>{title}</h1>
                </div>

                {renderHeader && <div className="drawer-header">{renderHeader()}</div>}

                {getFields && (
                    <div>
                        {renderInputComponentList(
                            getFields?.(state, setState) || {},
                            handleFieldChange,
                            formData || state.commonData,
                            errors,
                            false,
                            readOnly,
                            true,
                            false,
                            false,
                            '',
                            (update: any) => {
                                setState((prev) => {
                                    const newState = { ...prev, ...update };
                                    if (onFieldChange && update.commonData) {
                                        Object.entries(update.commonData).forEach(([key, value]) => {
                                            onFieldChange(key, value);
                                        });
                                    }
                                    return newState;
                                });
                            }
                        )}
                    </div>
                )}
                {extraContent && extraContent()}
                {!readOnly && onSubmit && (
                    <div>
                        <button className="drawer-submit-button" onClick={handleSubmit} style={{ width: '200px' }} disabled={state.isLoading}>
                            {state.isLoading ? 'Loading...' : actionStr}
                        </button>
                        {renderInputError(errors as Record<string, string>, 'submit')}
                        <div style={{ clear: 'both', height: '20px' }} />
                    </div>
                )}
                <div className="addNewAdvisorToFirm-cancel" onClick={close}>
                    <span className="a">Cancel</span>
                </div>
                <div style={{ clear: 'both', height: '20px' }} />
                {onDelete && (
                    <>
                        <div className="addNewAdvisorGroup-cancel">
                            <span
                                className="a"
                                onClick={() => {
                                    if (state.confirmDelete) {
                                        handleDelete();
                                    } else {
                                        setState((prev) => ({ ...prev, confirmDelete: true }));
                                        setTimeout(() => setState((prev) => ({ ...prev, confirmDelete: false })), 3000);
                                    }
                                }}
                                style={{ color: 'var(--color-dark-red)' }}
                            >
                                {state.confirmDelete ? 'Confirm' : 'Delete'}
                            </span>
                        </div>
                        <div style={{ clear: 'both', height: '20px' }} />
                    </>
                )}
                <div className="drawer-additional-content">{children}</div>
            </div>
            <div style={{ clear: 'both', height: '300px' }} />
        </>
    );
};

export default withRouter(GenericDrawer);
