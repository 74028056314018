import { Currency } from '../types';

const SESSION_STORAGE_KEY_PREFIX = 'globalProperty';

export enum GlobalPropertyKey {
    Currency = 'Currency',
    InvestmentCurrencies = 'InvestmentCurrencies',
}

const GlobalPropertyTypes: Record<GlobalPropertyKey, { name: string; isValid: (value: any) => boolean }> = {
    [GlobalPropertyKey.Currency]: {
        name: 'Currency',
        isValid: (value: any) => value === null || Object.values(Currency).includes(value),
    },
    [GlobalPropertyKey.InvestmentCurrencies]: {
        name: 'InvestmentCurrencies',
        isValid: (value: any) => value === null || (Array.isArray(value) && value.every((v) => Object.values(Currency).includes(v))),
    },
} as const;

export type GlobalPropertyType = {
    [K in GlobalPropertyKey]: ReturnType<(typeof GlobalPropertyTypes)[K]['isValid']> extends true ? any : never;
};

const getSessionStorageItem = (key: GlobalPropertyKey) => {
    return sessionStorage.getItem(`${SESSION_STORAGE_KEY_PREFIX}-${key}`);
};

const setSessionStorageItem = (key: GlobalPropertyKey, value: any) => {
    sessionStorage.setItem(`${SESSION_STORAGE_KEY_PREFIX}-${key}`, value);
};

// holds a set of setter functions for each property
const subscribers = new Map<GlobalPropertyKey, Set<Function>>();

// holds the current values of the properties
const globalProperties = new Proxy<Record<GlobalPropertyKey, any>>(
    {
        [GlobalPropertyKey.Currency]: getSessionStorageItem(GlobalPropertyKey.Currency) || null,
        [GlobalPropertyKey.InvestmentCurrencies]: getSessionStorageItem(GlobalPropertyKey.InvestmentCurrencies) || null,
    },
    {
        set(target, property: GlobalPropertyKey, value: any) {
            const { isValid } = GlobalPropertyTypes[property];
            if (isValid(value) && target[property] !== value) {
                target[property] = value;
                setSessionStorageItem(property, value);
                notifySubscribers(property, value);
            }
            return true;
        },
        get(target, property: GlobalPropertyKey) {
            return target[property];
        },
    }
);

export const subscribeGlobalPropertyChange = (property: GlobalPropertyKey, callback: (value: any) => void): (() => void) => {
    if (!subscribers.has(property)) {
        subscribers.set(property, new Set<Function>());
    }
    subscribers.get(property)?.add(callback);
    return () => subscribers.get(property)?.delete(callback);
};

const notifySubscribers = (property: GlobalPropertyKey, value: any): void => {
    subscribers.get(property)?.forEach((callback) => callback(value));
};

export const getSetGlobalProperty = (property: GlobalPropertyKey, value?: any): any => {
    if (value !== undefined) {
        globalProperties[property] = value;
    }
    if (globalProperties[property] === 'null') {
        globalProperties[property] = null;
    }

    // if the property is InvestmentCurrencies and the value is a string, convert it to an array of currencies
    if (property === GlobalPropertyKey.InvestmentCurrencies && typeof globalProperties[property] === 'string') {
        globalProperties[property] = globalProperties[property].split(',').map((currency: string) => currency.trim() as Currency);
    }

    // sort array, if the property is InvestmentCurrencies and the value is an array of currencies
    if (property === GlobalPropertyKey.InvestmentCurrencies && Array.isArray(globalProperties[property]) && globalProperties[property].length > 0) {
        globalProperties[property].sort((a: Currency, b: Currency) => Object.values(Currency).indexOf(a) - Object.values(Currency).indexOf(b));
    }

    return globalProperties[property];
};
