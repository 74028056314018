import _ from 'lodash';
import React from 'react';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router-dom';

import api from '../../api';
import api2 from '../../api2';
import PaginationFooter from './PaginationFooter';
import Table from './Table';

import Select from 'react-select';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { setQueryParam } from '../../utilities/query/queryParamHelpers';

import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import SelectDropdown from '../Dropdowns/SelectDropdown';
import Checkbox from '../Inputs/Checkbox';
import Search from '../Inputs/Search';
import HamburgerMenu from '../Menus/HamburgerMenu';

import '../../styles/paginationTable.css';
import '../../styles/react-datepicker.css';
import { fromQueryString, toQueryString } from '../../utilities/apiHelpers/queryString';
import { generateStringHash } from '../../utilities/string/generateStringHash';
import { InvestmentSearchFilter } from '../Filters/GenericSearchFilter';

const N_ROWS_OPTS = [10, 20, 50, 100];
const ALL_TYPES = 'All Types';
const DROPDOWN_HEIGHT = '30px';
const PAGINATION_LIMIT_DEFAULT = 100;

class PaginationTable extends React.PureComponent {
    state = {
        alreadyShowingEmptyState: false,
        dataFetchError: false,
        filteredData: [],
        total: 0,
        page: 0,
        rows: N_ROWS_OPTS[1],

        searchStringMap: {},
        searchString: '',
        type: this.props.allTypesName,
        startDate: null,
        endDate: null,
        sort: null, // {col, idx, ascending}
        lastLoadStart: 0,

        value: '',
        field: '',
        csvData: [],

        selectedElements: {},
        lastSelectedElement: null,
        keysHeld: {},
        useStickyHeader: undefined,

        queryParamsLoaded: false,
    };

    latestQueryHashRef = React.createRef();

    csvLink = React.createRef();

    componentDidMount = async () => {
        window.addEventListener('keyup', this.onKeyUp);
        window.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('mousedown', this.preventShiftSelect);
        // if props.showEmptyState, stop all processing and api calls, and use props.emptyStateData instead
        if (this.props.showEmptyState) {
            this.props.loaded();
            return;
        }

        await this.loadStorageParams();

        await this.loadQueryParams();

        if (this.props.setCallables) {
            this.props.setCallables({
                filterData: this.onFilterData,
                rowClicked: this.rowClicked,
                expandAllRows: this.expandAllRows,
                updateRow: this.updateRow,
                loadData: this.load,
                getData: () => this.state.filteredData,
                data: this.state.filteredData,
            });
        }

        if (this.props.initialSortField) {
            // let sort = {
            //     ascending: this.props.initialSortAscending ?? false,
            //     col: { sort: { field: this.props.initialSortField, } },
            // }
            // this.onSort(sort)
            return;
        }

        this.load(true);
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const p = this.props;
        const s = this.state;
        // if props.showEmptyState, stop all processing and api calls, and use props.emptyStateData instead
        if (this.props.showEmptyState && !this.state.alreadyShowingEmptyState) {
            this.props.loaded();
            this.setState({ alreadyShowingEmptyState: true });
            return;
        }
        const shouldReload = p.reload && !prevProps.reload;
        if (
            !_.isEqual(p.match.params.account, prevProps.match.params.account) ||
            !_.isEqual(p.match.params.investment, prevProps.match.params.investment) ||
            !_.isEqual(p.joins, prevProps.joins) ||
            // !_.isEqual(p.route, prevProps.route) ||
            !_.isEqual(p.types, prevProps.types) ||
            !_.isEqual(p.defaultFilters, prevProps.defaultFilters) ||
            !_.isEqual(p.forceSeeAll, prevProps.forceSeeAll) ||
            !_.isEqual(p.blocked, prevProps.blocked) ||
            // !_.isEqual(this.state, prevState) ||
            shouldReload
        ) {
            await this.loadQueryParams();
            this.load(true);
        }

        // set page to 0 if route changes
        if (!_.isEqual(p.route, prevProps.route) || !_.isEqual(s.rows, prevState.rows)) {
            this.setPage(0);
        }
    };

    componentWillUnmount = () => {
        // clearQueryParams()
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener('keydown', this.onKeyDown);
        document.removeEventListener('mousedown', this.preventShiftSelect);
    };

    makeApiRequest = async (url, useApi2 = false) => {
        const p = this.props;
        if (useApi2) {
            try {
                // handle limit param in query
                const urlRoute = url.split('?')[0];
                const urlParams = url.split('?')[1];
                const urlParamsObj = fromQueryString(urlParams);
                const limit = urlParamsObj.limit ?? PAGINATION_LIMIT_DEFAULT;
                const mustPaginate = limit > PAGINATION_LIMIT_DEFAULT || p.forceSeeAll;

                let data = [];
                let total = 0;

                if (mustPaginate) {
                    // need to paginate if getting more than 100 results
                    data = await api2.paginateApiRoute(async (params) => {
                        const nextUrlParams = { ...urlParamsObj, ...params };
                        const nextUrl = `${urlRoute}?${toQueryString(nextUrlParams)}`;
                        let nextResData = (await api2.get(nextUrl)).data;
                        nextResData = p.preProcessData?.(nextResData) ?? nextResData;
                        const nextData = p.getResultsFromResponse?.(nextResData) ?? nextResData.results;
                        const nextTotal = p.getTotalFromResponse?.(nextResData) ?? nextResData.total;
                        // each call should have the same total value, so only set it once
                        if (nextTotal !== 0 && total !== nextTotal) {
                            total = nextTotal;
                        }
                        return nextData;
                    });
                } else {
                    // no need to paginate
                    let resData = (await api2.get(url)).data;
                    resData = p.preProcessData?.(resData) ?? resData;
                    data = p.getResultsFromResponse?.(resData) ?? resData.results;
                    total = p.getTotalFromResponse?.(resData) ?? resData.total;
                }

                return { data, total };
            } catch (error) {
                console.log('Pagination Table Error', error.toString());
                return null;
            }
        } else {
            // use old api
            const res = await api.get(url);
            if (!res) throw new Error('Failed to fetch data');
            const data = p.getResultsFromResponse?.(res) ?? res.results;
            const total = p.getTotalFromResponse?.(res) ?? res.total;
            return { data, total };
        }
    };

    // get query param string
    // if includePaginationTableStr is true, add "pagTable_" to the beginning of the string
    getQueryParamString = (key, includePaginationTableStr = false) => {
        let res = this.props.name ? `${this.props.name}_${key}` : key;
        if (includePaginationTableStr) {
            res = `pagTable_${res}`;
        }
        return res;
    };

    loadStorageParams = async () => {
        // load "pagination_table_rows" from local storage
        const rows_param_str = this.getQueryParamString('rows', true);
        const rows = localStorage.getItem(rows_param_str);
        if (rows) {
            this.setState({ rows: Number(rows) });
        }
    };

    loadQueryParams = async () => {
        const s = this.state;
        const p = this.props;

        const url = new URL(window.location.href);
        let startDate = url.searchParams.get(this.getQueryParamString('start'));
        let endDate = url.searchParams.get(this.getQueryParamString('end'));
        let rows = url.searchParams.get(this.getQueryParamString('rows'));
        let page = url.searchParams.get(this.getQueryParamString('page'));
        let type = url.searchParams.get(this.getQueryParamString('type'));
        let useStickyHeader = url.searchParams.get(this.getQueryParamString('sticky_header'));

        const isDate = (dateString) => new Date(dateString) instanceof Date && !isNaN(new Date(dateString).getTime());

        startDate = startDate !== null && isDate(startDate) ? dateToUTCMidnight(startDate) : s.startDate;
        endDate = endDate !== null && isDate(endDate) ? dateToUTCMidnight(endDate) : s.endDate;
        rows = rows !== null ? Number(rows) : s.rows;
        page = page !== null ? Number(page) - 1 : s.page;
        type = type !== null ? type : s.type;
        useStickyHeader = useStickyHeader !== null ? useStickyHeader === 'true' : s.useStickyHeader;

        if (this.useTypesListAsObjectWithLabelAndValue() && type) {
            type = this.props.types.find((t) => t.value === type)?.value;
        }

        const searchStringMap = {};
        if (p.searchBoxes?.length) {
            const queryKeys = p.searchBoxes
                .map((sb) => sb.queryKey ?? 'search')
                .filter((qk) => qk)
                .filter((v, i, a) => a.indexOf(v) === i);
            queryKeys.forEach((qk) => {
                const queryParamKey = this.getQueryParamString(qk);
                const queryValue = url.searchParams.get(queryParamKey);
                searchStringMap[qk] = queryValue;
            });
        }
        this.setState({ searchStringMap });

        if (startDate !== s.startDate || endDate !== s.endDate || rows !== s.rows || page !== s.page || type !== s.type || useStickyHeader !== s.useStickyHeader) {
            this.setState({ startDate, endDate, rows, page, type, useStickyHeader, queryParamsLoaded: true }, () => {
                this.load();
            });
        } else {
            this.setState({ queryParamsLoaded: true });
        }
    };

    setDefaultDates = () => {
        const nowUtc = dateToUTCMidnight();
        this.setState({
            startDate: dateToUTCMidnight(nowUtc.getTime() - 10 * 365 * 24 * 60 * 60 * 1000),
            endDate: nowUtc,
        });
    };

    onFilterData = (value, field, filterObj) => {
        this.setState({ page: 0 });
        this.load(true, value, field, filterObj);
    };

    rowClicked = (idx) => {
        const data = [...this.state.filteredData];
        const item = data[idx];
        item.expanded = !item.expanded ?? true;
        this.setState({ filteredData: data });
    };

    expandAllRows = (expanded = true) => {
        const data = [...this.state.filteredData];
        data.forEach((d) => (d.expanded = expanded));
        this.setState({ filteredData: data });
    };

    updateRow = (item, idx) => {
        const data = [...this.state.filteredData];
        data[idx] = item;
        this.setState({ filteredData: data });
    };

    getQuery = (skip, count, filterValue = '', filterField = '') => {
        const s = this.state;
        const p = this.props;
        const m = p.match.params;

        const urlParams = p.route.split('?')[1] ?? '';
        const urlParamsObj = fromQueryString(urlParams) ?? {};

        filterValue = filterValue === '' ? p.filterValue : filterValue;
        filterField = filterField === '' ? p.filterField : filterField;

        let query;

        query = p.route.includes('?') ? '&' : '?';

        // safeguard for when the route isn't properly controlling, but it should, since it needs to for admins and advisors
        // for account and investment in the URL params
        // if that prop is being controlled by the parent of this component, use that value
        // otherwise, use the value in the URL
        if (m.account && m.account !== 'all') {
            if (p.useApi2 && !Object.keys(urlParamsObj).includes('accounts')) {
                query += `accounts=${m.account}&`;
            } else if (!Object.keys(urlParamsObj).includes('account')) {
                query += `account=${m.account}&`;
            }
        }

        if (m.investment) {
            if (p.useApi2 && !Object.keys(urlParamsObj).includes('investments')) {
                query += `investments=${m.investment}&`;
            } else if (!Object.keys(urlParamsObj).includes('investment')) {
                query += `investment=${m.investment}&`;
            }
        }

        // Pagination
        query += `skip=${skip}&count=${p.forceSeeAll ? -1 : count}&limit=${p.forceSeeAll ? -1 : count}`;

        if (filterField && !p.search) {
            query += `&filter=${filterValue}&filterField=${filterField}`;
        }

        // add search string map to query
        if (p.searchBoxes?.length) {
            for (const [key, value] of Object.entries(s.searchStringMap)) {
                if (value) {
                    query += `&${key}=${value}`;
                }
            }
        }

        if (p.aggregate) {
            query += `&aggregate=${p.aggregate}`;
        }

        const usePseudoType = p.pseudoTypes && Object.keys(p.pseudoTypes).includes(s.type);
        if (!p.typeCallback && s.type && ![p.allTypesName, ALL_TYPES].includes(s.type) && !usePseudoType && !p.disableTypeFilter) {
            query += `&${p.typeParameterName ?? 'types'}=` + s.type;
        } else if (usePseudoType) {
            query += `&${p.pseudoTypes[s.type].fieldName}=${s.type}`;
        }

        if (s.startDate) query += '&start=' + (p.useApi2 ? new Date(s.startDate).toISOString().split('T')[0] : s.startDate);

        if (s.endDate) query += '&end=' + (p.useApi2 ? new Date(s.endDate).toISOString().split('T')[0] : s.endDate);

        if (p.dateFilterField) {
            if (!p.useApi2) {
                query += `&dateFilterField=${p.dateFilterField}`;
            }
        }

        if (s.sort && !p.doNotSort) {
            if (s.sort.col?.sort?.field) {
                query += (p.useApi2 ? '&sort_field=' : '&sortField=') + s.sort.col.sort.field;
            }
            if (s.sort.ascending === false) {
                query += p.useApi2 ? '&sort_direction=desc' : '&sortReverse=true';
            }
        }
        return query;
    };

    load = async (showLoad = true, value = '', field = '') => {
        const s = this.state;
        const p = this.props;
        const thisLoadStart = +dateToUTCMidnight();
        let dataFetchError = false;

        if (p.forceEmpty || p.blocked) {
            this.setState({ total: 0, filteredData: [] });
            p.loaded();
            return;
        }

        const loadIdentifier = 'paginationTableLoad' + Math.random().toString(36).substring(2, 15);

        if (showLoad) await p.loading?.(320, loadIdentifier);

        let data = [],
            total = 0;

        let query = this.getQuery(s.page * s.rows, s.rows, value, field);
        const currentQueryHash = generateStringHash(query);
        this.latestQueryHashRef.current = currentQueryHash;

        this.props.preFetchCallback?.();

        const queryUrl = p.route + query;

        try {
            const res = await this.makeApiRequest(queryUrl, this.props.useApi2);
            if (!res) {
                throw new Error('Failed to fetch data');
            }
            data = res.data;
            total = res.total;
        } catch (error) {
            console.log('Failed to fetch data on route:', p.route);
            console.log(error);
            dataFetchError = true;
        }

        // store field and value for getting query
        if (s.field !== field || s.value !== value) this.setState({ field, value });

        if (this.latestQueryHashRef.current === currentQueryHash) {
            this.setState(
                (state) => {
                    if (state.lastLoadStart <= thisLoadStart) {
                        return {
                            data,
                            total,
                            filteredData: data,
                            lastLoadStart: thisLoadStart,
                            dataFetchError,
                            selectedElements: {},
                            lastQueryUrl: queryUrl,
                        };
                    }
                },
                p.onReady ? () => p.onReady(data) : undefined
            );
        }
        this.props.loaded?.(loadIdentifier);
    };

    joinData = (data = []) => {
        if (this.props.joins) {
            for (const row of data) {
                for (const [key, map] of Object.entries(this.props.joins)) {
                    // sorry about this.  Sometimes it's e.id, sometimes it's e._id
                    const match = map.find((e) => [e._id, e.id].includes(row[key]) || (row[key]?._id && [e._id, e.id].includes(row[key]?._id)));
                    row[key + '_data'] = row[key] ? match : null;
                }
            }
        }
        return data;
    };

    getAllTypesName = () => this.props.allTypesName ?? ALL_TYPES;

    getTypes = () => {
        let types = [];
        if (this.props.types) {
            types = [...this.props.types];
            const allTypesName = this.props.allTypesName ?? ALL_TYPES;
            let allTypesElement = allTypesName;
            // create an object with label and value if we're using types as an object
            if (this.useTypesListAsObjectWithLabelAndValue()) {
                allTypesElement = { label: allTypesName, value: allTypesName };
            }
            types.unshift(allTypesElement);
        }
        const pseudoTypes = Object.keys(this.props.pseudoTypes ?? {});
        let pseudoTypesElements = pseudoTypes;
        // create an object with label and value if we're using types as an object
        if (this.useTypesListAsObjectWithLabelAndValue()) {
            pseudoTypesElements = pseudoTypes.map((type) => ({ label: type, value: type }));
        }
        types = types.concat(pseudoTypesElements);
        return types;
    };

    onSort = (sort) => {
        this.setState({ sort }, () => this.load());
        if (this.props.onSort) this.props.onSort(sort);
    };

    getCsvTitle = () => {
        let title = this.props.csvTitle;
        // add filter info?
        return title;
    };

    exportToCsv = async () => {
        if (this.csvLink.current?.link) {
            this.props.loading?.(0, 'paginationTableExport');
            let csvData = await this.getCsvData();
            this.setState({ csvData }, async () => {
                await this.csvLink.current.link.click();
                this.setState({ csvData: [] });
                this.props.loaded?.('paginationTableExport');
            });
        }
    };

    getCsvData = async () => {
        const s = this.state;
        const p = this.props;

        let query = this.getQuery(0, 10000, s.value, s.field);

        const url = p.route + query;
        const urlRoute = url.split('?')[0];
        const urlParams = url.split('?')[1];
        const urlParamsObj = fromQueryString(urlParams);

        // get total by making a single call
        let total = 0;
        let data = [];
        try {
            if (p.useApi2) {
                const res = await api2.get(`${urlRoute}?${toQueryString({ ...urlParamsObj, limit: 1 })}`);
                total = p.getTotalFromResponse?.(res.data) ?? res.data.total;
            } else {
                const res = await api.get(url);
                total = p.getTotalFromResponse?.(res) ?? res.total;
            }

            data = await api2.paginateApiRouteConcurrently(async (params) => {
                const nextUrlParams = { ...urlParamsObj, ...params };
                const nextUrl = `${urlRoute}?${toQueryString(nextUrlParams)}`;
                const nextResData = p.useApi2 ? (await api2.get(nextUrl)).data : await api.get(nextUrl);
                const nextData = p.getResultsFromResponse?.(nextResData) ?? nextResData.results;
                return nextData;
            }, total);
        } catch (error) {
            console.log('Failed to fetch data on route:', url);
            console.log(error);
        }

        data = this.joinData(data);
        data = p.postProcessData?.(data, query) ?? data;

        // get extra data for all results that will be used in csvRow
        const extraData = (await p.csvExtraData?.(data)) ?? {};

        let res = [];
        res.push(p.getCsvHeaders?.(data) ?? p.csvHeaders);
        for (let i = 0; i < data.length; i++) {
            const d = data[i];
            res.push(await p.csvRow(d, extraData, data));
        }

        return res;
    };

    useTypesListAsObjectWithLabelAndValue = () => {
        const p = this.props;
        const types = p.types ?? [];
        const hasLabelField = !!types?.[0]?.label;
        const hasValueField = !!types?.[0]?.value;
        return hasLabelField && hasValueField;
    };

    getSubRowColumns = (data) => {
        if (!this.props.subRowColumns) return [];
        const p = this.props;
        const subRowColumns = p.subRowColumns ? [...p.subRowColumns] : [];

        // add left most column for bulk checkbox on main row only
        if (p.bulkActions?.length && data && !p.noBulkActionCheckboxes) {
            subRowColumns.unshift({});
        }

        return subRowColumns;
    };

    getColumns = (data) => {
        const s = this.state;
        const p = this.props;
        const columns = [...p.columns];
        // add left most column for bulk checkbox on main row only
        if (p.bulkActions?.length && data && !p.noBulkActionCheckboxes) {
            const allElementIds = data.map((m) => m._id);
            const checkboxStyle = { padding: 2, width: '20px', height: '20px' };
            const checkboxCellStyle = { paddingLeft: 25, paddingRight: 0, cursor: 'auto', width: '25px' };

            // add checkbox column
            columns.unshift({
                title: (
                    <Checkbox
                        style={checkboxStyle}
                        checked={allElementIds.every((id) => s.selectedElements[id])}
                        setChecked={(checked, e) => {
                            e.stopPropagation();
                            const selectedElements = allElementIds.reduce((acc, cur) => {
                                acc[cur] = checked;
                                return acc;
                            }, {});
                            this.setState({ selectedElements, lastSelectedElement: null });
                        }}
                    />
                ),
                render: (col, empty, items, idx) => {
                    const element = items[idx];
                    return (
                        <td
                            key={`pag_select_${idx}`}
                            style={checkboxCellStyle}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={checkboxStyle}
                                checked={s.selectedElements[element._id]}
                                setChecked={(checked, e) => {
                                    e.stopPropagation();
                                    this.doSelect(element, e);
                                }}
                            />
                        </td>
                    );
                },
                headerStyle: checkboxCellStyle,
            });
        }
        return columns;
    };

    doSelect = (val, event) => {
        if (!event) return;
        const selectedElements = this.state.selectedElements;
        // If shift is held, select all elements between lastSelectedElement and val
        const lastSelectedElement = this.state.lastSelectedElement;
        if (this.state.keysHeld.Shift && lastSelectedElement) {
            const dataIds = this.state.filteredData.map((m) => m._id);
            const lastSelectedElementIdx = dataIds.indexOf(lastSelectedElement._id);
            const valIdx = dataIds.indexOf(val._id);
            const startIdx = Math.min(lastSelectedElementIdx, valIdx);
            const endIdx = Math.max(lastSelectedElementIdx, valIdx);
            const isValSelected = !!selectedElements[val._id];

            for (let i = startIdx; i <= endIdx; i++) {
                const id = dataIds[i];
                selectedElements[id] = !isValSelected;
                // selectedElements[id] = isLastSelectedElementSelected
            }
        } else {
            selectedElements[val._id] = !selectedElements[val._id];
        }
        this.setState({ selectedElements, lastSelectedElement: val });
    };

    preventShiftSelect = (e) => {
        if (e.shiftKey) {
            e.preventDefault();
        }
        if (e.ctrlKey) {
            e.preventDefault();
        }
    };

    onKeyUp = (e) => {
        const keysHeld = this.state.keysHeld;
        delete keysHeld[e.key];
        this.setState({ keysHeld });
    };
    onKeyDown = (e) => {
        const keysHeld = this.state.keysHeld;
        keysHeld[e.key] = true;
        this.setState({ keysHeld });
    };

    setPage = (page) => {
        this.setState({ page }, () => {
            if (page === 0 || !page) {
                setQueryParam(this.getQueryParamString('page'), null);
            } else {
                setQueryParam(this.getQueryParamString('page'), page + 1);
            }
            this.load(true);
        });
    };

    renderBulkMenu = (data) => {
        if (this.props.renderBulkMenu) return this.props.renderBulkMenu(data);

        if (!this.props.bulkActions?.length || !data) return;

        const selectedElements = data.filter((d) => this.state.selectedElements[d._id]);

        // if (!selectedElements.length) return

        // bulk actions "action" function can take in selectedElements as an argument
        const bulkActions =
            this.props.bulkActions?.map((actionObj) => {
                return {
                    label: actionObj.label,
                    action: !actionObj.action
                        ? null
                        : async () => {
                              const these_callables = {
                                  reload: this.load,
                                  getData: () => this.state.filteredData,
                              };
                              actionObj.action(selectedElements, these_callables);
                          },
                    disabled: actionObj.disabled,
                    subOptions:
                        actionObj?.subOptions?.map((subOpt) => {
                            return {
                                label: subOpt.label,
                                action: async () => {
                                    const these_callables = {
                                        reload: this.load,
                                        getData: () => this.state.filteredData,
                                    };
                                    subOpt.action(selectedElements, these_callables);
                                },
                                disabled: subOpt.disabled,
                            };
                        }) ?? [],
                };
            }) ?? [];

        const menuOptions = [...(selectedElements.length || this.props.noBulkActionCheckboxes ? bulkActions : [])];

        return <HamburgerMenu containerStyle={{ zIndex: 1 }} options={menuOptions} />;
    };

    renderSelectDropdown = (dropdownObject, dropdownObjectIdx = -1) => {
        return <SelectDropdown object={dropdownObject} idx={dropdownObjectIdx} />;
    };

    // renderDateFilters = () => {

    //     const s = this.state
    //     const p = this.props

    //     return <div className='pagTable_datePickers'
    //         style={{
    //             position: 'relative',
    //             display: 'flex',
    //             alignItems: 'center',
    //             marginLeft: '5px',
    //             zIndex: 0,
    //         }}>

    //         {/* Start Date picker */}
    //         <div className='pagTable_datePickerContainer' style={{ width: 115, }}>
    //             {/* <div className='pagTable_datePickerText'>start date</div> */}
    //             Start Date
    //             <UTCDatePicker
    //                 style={{ height: '40px' }}
    //                 placeholderText='MM/DD/YYYY'
    //                 dateFormat='MM-dd-yyyy'
    //                 selected={s.startDate}
    //                 onChange={startDate => {
    //                     setQueryParam(this.getQueryParamString('start'), startDate ? startDate.getTime() : null)
    //                     let _ = p.setStartDate?.(startDate)
    //                     this.setState({ startDate }, () => this.load(true))
    //                 }}
    //             />
    //         </div>

    //         <div className='pagTable_datePicker_to'>to</div>

    //         {/* End Date picker */}
    //         <div className='pagTable_datePickerContainer' style={{ width: 115, }}>
    //             {/* <div className='pagTable_datePickerText'>end date</div> */}
    //             End Date
    //             <UTCDatePicker
    //                 style={{ height: '40px', }}
    //                 placeholderText='MM/DD/YYYY'
    //                 dateFormat='MM-dd-yyyy'
    //                 selected={s.endDate}
    //                 onChange={endDate => {
    //                     setQueryParam(this.getQueryParamString('end'), endDate ? endDate.getTime() : null)
    //                     let _ = p.setEndDate?.(endDate)
    //                     this.setState({ endDate }, () => this.load(true))
    //                 }}
    //             />
    //         </div>
    //     </div>

    // }

    renderInvestmentFilter = () => {
        const p = this.props;
        // no investment routes in api 2 yet
        // if (p.useApi2) return;
        if (p.noInvestmentDropdown) return;
        if (!p.setSelectedInvestment) return;

        return (
            <>
                <div
                    style={{
                        marginLeft: 5,
                        ...(p.individualCalls ? { marginRight: 0 } : {}),
                    }}
                >
                    <span>Investment</span>
                    <InvestmentSearchFilter
                        filter={{
                            ...(p.relevantUser?._id && { users: [p.relevantUser._id] }),
                        }}
                        placeholder="Filter by investment"
                        selected={p.selectedInvestment}
                        onChange={(invObj) => p.setSelectedInvestment?.(invObj)}
                        getLabel={(obj) => {
                            if (!obj?._id) return null;
                            return (
                                <div>
                                    {!p.relevantUser && obj.user?.name && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{obj.user?.name}</div>}
                                    {obj.account?.name && <div style={{ fontSize: '12px', color: 'var(--color-light-gray)' }}>{obj.account?.name}</div>}
                                    <div>{obj.name}</div>
                                </div>
                            );
                        }}
                        defaultLabel="All Investments"
                        matchProperty="_id"
                        width={300}
                        defaultOptions={true}
                    />
                </div>
            </>
        );
    };

    renderTypesDropdown = () => {
        const s = this.state;
        const p = this.props;
        const types = this.getTypes();
        return (
            !p.individualCalls &&
            !p.noTypeDropdown &&
            types.length > 0 && (
                <div style={{ marginLeft: 5 }}>
                    {/* Types dropdown */}
                    <span>Type</span>
                    <Select
                        placeholder={'Filter by Type'}
                        options={types.map((t) => {
                            if (typeof t === 'string') {
                                return { label: this.props.getTypeLabel?.(t) ?? t, value: t };
                            }
                            return t;
                        })}
                        value={{ label: this.props.getTypeLabel?.(s.type) || s.type || this.getAllTypesName(), value: s.type }}
                        onChange={(type) => {
                            // if type[0] = {label, value}, then use value
                            const typeValue = type.value;
                            setQueryParam(this.getQueryParamString('type'), typeValue);
                            if (p.typeCallback) {
                                p.typeCallback(typeValue);
                            } else {
                                if (typeValue === s.type) return;
                                this.setState({ type: typeValue }, () => this.load(true));
                            }
                        }}
                        styles={{
                            // width
                            control: (provided) => ({
                                ...provided,
                                width: p.typesDropdownStyle?.width ?? 185,
                                minWidth: p.typesDropdownStyle?.minWidth ?? null,
                                // height: props.height,
                            }),
                            menu: (provided) => ({
                                ...provided,
                                width: p.typesDropdownStyle?.width ?? 185,
                                minWidth: p.typesDropdownStyle?.minWidth ?? null,
                                // height: props.height,
                            }),
                            // ...p.typesDropdownStyle,
                        }}
                    />
                </div>
            )
        );
    };

    renderFilterRow = () => {
        const p = this.props;

        const filters = [
            ...(p.selectFilters?.map((x, xIdx) => this.renderSelectDropdown(x, xIdx)) ?? []),
            this.renderInvestmentFilter(),
            this.renderTypesDropdown(),
            // ...([!p.noDateFilters && <div>{this.renderDateFilters()}</div>] ?? []),
        ].filter((x) => x);

        // if no filters, don't render
        if (filters.length === 0) return;

        return (
            <div className="pagTable_selectFilters_container pdfIgnore" id="pagTable_filterRow">
                <div className="pagTable_selectFilters" style={{ width: 'calc(100%)' }}>
                    {filters.map((f, idx) => (
                        <span style={{ marginTop: '10px' }} key={`pag_selectFilters_${idx}`}>
                            {f}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    renderSearchBoxes = () => {
        const p = this.props;
        if (!p.searchBoxes) return;
        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    ...this.props.searchBoxesContainerStyle,
                }}
            >
                {p.searchBoxes?.map((sb) => {
                    return this.renderSearchBox(sb);
                })}
            </div>
        );
    };

    renderFooter = () => {
        return (
            <div className="pdfIgnore" style={{ zIndex: 2 }}>
                {!this.props.noFooter ? (
                    <PaginationFooter
                        forceSeeAll={this.props.forceSeeAll}
                        dropdownHeight={DROPDOWN_HEIGHT}
                        numRowsOptions={N_ROWS_OPTS}
                        defaultRowsPerPage={this.state.rows}
                        page={this.state.page}
                        rowsPerPage={this.state.rows}
                        total={this.state.total}
                        setRowsPerPage={(rows) => {
                            // set local storage
                            localStorage.setItem(this.getQueryParamString('rows', true), rows);
                            setQueryParam(this.getQueryParamString('rows'), rows);
                            this.setState({ rows }, () => this.load(true));
                        }}
                        setPage={this.setPage}
                        refreshData={() => {
                            this.load();
                            this.props.refreshDataCallback?.();
                        }}
                        useStickyHeader={!this.props.allowStickyHeader ? undefined : this.shouldRenderStickyHeader()}
                        setUseStickyHeader={
                            !this.props.allowStickyHeader
                                ? undefined
                                : (useStickyHeader) => {
                                      this.setState({ useStickyHeader });
                                      setQueryParam(this.getQueryParamString('sticky_header'), useStickyHeader || null);
                                  }
                        }
                    />
                ) : (
                    <div style={{ height: `calc(${DROPDOWN_HEIGHT} + 20px)` }} />
                )}
            </div>
        );
    };

    // params: title, placeholder, style, titleStyle, callback
    renderSearchBox = (params = {}) => {
        const s = this.state;
        const queryKey = params.queryKey ?? 'search';
        return (
            <div>
                {/* title */}
                {params?.title && <label style={{ marginLeft: 5, fontSize: '16px', ...params?.titleStyle }}>{params.title}</label>}
                {/* search bar */}
                <Search
                    value={s.searchStringMap[queryKey] ?? ''}
                    placeholder={params?.placeholder ?? 'Search'}
                    callback={(searchString) => {
                        setQueryParam(this.getQueryParamString(queryKey), searchString);
                        this.setState(
                            {
                                searchStringMap: {
                                    ...s.searchStringMap,
                                    [queryKey]: searchString,
                                },
                            },
                            () => {
                                this.load(true);
                                params?.callback?.(searchString);
                            }
                        );
                    }}
                    style={{
                        width: '300px',
                        ...params?.style,
                    }}
                />
            </div>
        );
    };

    shouldShowCsvExport = () => {
        return this.props.csvRow && (this.props.csvHeaders || this.props.getCsvHeaders);
    };

    shouldRenderStickyHeader = () => {
        if (!this.props.allowStickyHeader) return false;
        if (![true, false].includes(this.state.useStickyHeader)) {
            // if allowed, but not tracking in state yet, use default
            const useStickyHeader = [true, false].includes(this.props.stickyHeaderDefault) ? this.props.stickyHeaderDefault : true;
            this.setState({ useStickyHeader });
            setQueryParam(this.getQueryParamString('sticky_header'), useStickyHeader || null);
            return useStickyHeader;
        }
        return this.state.useStickyHeader;
    };

    render = () => {
        const p = this.props; // Shorthand
        const s = this.state;

        if (!this.state.queryParamsLoaded) return null;

        // if props.showEmptyState, stop all processing and api calls, and use props.emptyStateData instead
        const joinedData = p.showEmptyState ? p.emptyStateData : JSON.parse(JSON.stringify(this.joinData(s.filteredData)));

        const data = p.postProcessData?.(joinedData, s.lastQueryUrl) ?? joinedData;
        const rowInfo = p.rowInfo ? p.rowInfo : {};

        if (p?.enableClickSelect && p.bulkActions?.length) {
            const baseRowClick = rowInfo?.rowClick;
            rowInfo['rowClick'] = (dataItems, idx, e) => {
                baseRowClick?.(dataItems, idx);
                const val = dataItems[idx];
                this.doSelect(val, e);
            };
        }

        return (
            <div className="pagTable" style={{ ...p.containerStyle }}>
                {/* {p.noHeader && p.title && <div className='pagTable_headerContainer' style={p.headerStyle}>
                <div className='pagTable_headerText' style={{ ...p.titleStyle, }}>
                    {p.title}
                </div>
            </div>}
            {!p.noHeader && (p.title) && <div className='pagTable_headerContainer' style={p.headerStyle}>
                <div className='pagTable_leftHeader pagTable_headerItem'>
                    <div className='pagTable_headerText' style={p.titleStyle}>{p.title}</div>
                </div>
            </div>} */}

                {p.title && (
                    <div className="pagTable_headerContainer" style={p.headerStyle}>
                        <div className="pagTable_leftHeader pagTable_headerItem">
                            <div className="pagTable_headerText" style={p.titleStyle}>
                                {p.title}
                            </div>
                        </div>
                    </div>
                )}

                {this.renderSearchBoxes(data)}

                {this.renderFilterRow(data)}

                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', marginTop: '20px', ...p.bulkMenuStyle }}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        {this.renderBulkMenu(data)}
                        {this.props.bulkMenuRightComponent && this.props.bulkMenuRightComponent(data)}
                    </div>
                    {!p.noTopPatination && this.renderFooter()}
                </div>

                {!p.hideTable && (
                    <>
                        <Table
                            mobileHeader={p.mobileHeader}
                            noHoverColor={p.noHoverColor}
                            includeHeader={p.includeHeader}
                            columns={this.getColumns(data)}
                            subRowColumns={this.getSubRowColumns(data)}
                            getSubRowItemsFromMainItem={p.getSubRowItemsFromMainItem}
                            items={data}
                            rowInfo={rowInfo}
                            renderHeader={p.renderHeader ? p.renderHeader : undefined}
                            onSort={this.onSort}
                            csvTitle={this.getCsvTitle()}
                            headerCellStyle={p.headerCellStyle}
                            headerCellTitleStyle={p.headerCellTitleStyle}
                            initialSortField={p.initialSortField}
                            initialSortAscending={p.initialSortAscending}
                            startSortDescending={p.startSortDescending}
                            onUpdateItem={(newItem, itemListIdx) => {
                                const newData = [...data];
                                newData[itemListIdx] = newItem;
                                this.setState({ filteredData: newData });
                            }}
                            useStickyHeader={this.shouldRenderStickyHeader()}
                        />

                        {s.dataFetchError && <div className="pagTable_error">There was an error fetching data. Please try again later.</div>}

                        <div
                            style={{
                                position: 'relative',
                                // backgroundColor: 'red',
                                marginTop: '20px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            {p.renderBottomLeftComponent && <div>{p.renderBottomLeftComponent()}</div>}

                            {p.csvRow && this.shouldShowCsvExport() ? (
                                <>
                                    <button
                                        id="pagTable-exportCsv"
                                        className="pdfIgnore"
                                        style={{
                                            width: '130px',
                                            margin: 0,
                                            marginTop: '55px',
                                            position: 'absolute',
                                            left: '50%',
                                            top: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                        onClick={() => {
                                            setDismissableAlert(p.setAlert, 'Exporting to CSV...');
                                            try {
                                                this.exportToCsv();
                                            } catch (error) {
                                                setDismissableAlert(p.setAlert, 'Error exporting to CSV.', true);
                                            }
                                        }}
                                    >
                                        Export To CSV
                                    </button>

                                    <CSVLink
                                        data={s.csvData}
                                        // data={[['a','b']]}
                                        filename={`${p.csvTitle ?? 'data'}.csv`}
                                        className="hidden"
                                        ref={this.csvLink}
                                        target="_blank"
                                    />
                                    {/* </div> */}
                                </>
                            ) : (
                                <div />
                            )}

                            {!p.noBottomPatination && (
                                <div
                                    style={{
                                        // backgroundColor: 'green',
                                        right: 0,
                                        // marginTop: '10px',
                                    }}
                                >
                                    {this.renderFooter()}
                                </div>
                            )}
                        </div>

                        <div style={{ clear: 'both', height: this.props.paddingBottom ?? '100px' }} />
                    </>
                )}
            </div>
        );
    };
}

export default withRouter(PaginationTable);
