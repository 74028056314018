import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Table from './Table';

import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _, { isNaN } from 'lodash';
import api from '../../api';
import { getInvestmentStats } from '../../common/investments/getInvestmentStats';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import { CURRENCIES, CURRENCY_SYMBOLS, getCurrencyString } from '../../constants/currencyConstants';
import '../../styles/widget-investmentDetails.css';
import '../../styles/widget.css';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import formatDate from '../../utilities/format/formatDate';

const isMobile = window.innerWidth <= 768;

//TODO: File should be made shorter;
//I recommend separating data calculations from render-functions
class InvestmentDetailsTable extends Component {
    /**
     * Displays a table tracking performance of investments.
     * NOTE: must REPLACE the investment props when updating table.
     * array manipulation will not trigger a rerender.
     */
    state = {
        expanded: new Set(),
        sortState: null,
    };

    componentDidMount = () => {
        this.handleEmpty();
        this.loadData();
    };

    componentDidUpdate = (prev) => {
        if (!_.isEqual(this.props.investments, prev.investments)) {
            this.handleEmpty();
            this.loadData();
        }
    };

    loadData = async () => {};

    getAllActiveSuffix = () => (this.props.globalInvestmentFilter === 'Active' ? '_active' : '');

    // opens the last item for display if showing empty state fake data
    handleEmpty = () => {
        if (this.props.showEmptyState) {
            this.toggleExpanded(this.props.investments[this.props.investments.length - 1]?.type);
        }
    };

    rowInfo = {
        selectClass: (dataItems, idx) => {
            if (dataItems[idx].isSummaryRow) {
                return isMobile ? 'mobile-investment-details-sum' : 'investment-details-sum';
            } else if (dataItems[idx].isAggRow) {
                return isMobile ? 'mobile-investment-details-agg' : 'investment-details-agg';
            } else {
                return isMobile ? 'mobile-investment-details-investment' : 'investment-details-investment';
            }
        },
        rowClick: (dataItems, idx) => {
            const isInvestment = !dataItems[idx]?.isAggRow && !dataItems[idx]?.isSummaryRow;
            if (isInvestment) {
                if (this.props.advisorVue) {
                    const pathPrefix = this.props.user.access === 'advisor' ? '/advisor/clients' : '/admin/users';
                    this.props.history.push(`${pathPrefix}/${this.props.match.params.id}/details/${dataItems[idx]._id}`);
                } else {
                    this.props.history.push(`/accounts/${this.props.account?._id ?? 'all'}/investments/${dataItems[idx]._id}/details`);
                }
            }
        },
    };

    dropdownAction = (item) => (item.isAggRow && !item.isSummaryRow ? this.toggleExpanded(item.name) : {});

    renderInvestmentTypeHeader = () => {
        if (isMobile)
            return (
                <div className={'mobile-investment-details-type'} key="idettypehead">
                    Investment Type
                </div>
            );
        return (
            <th className={'investment-details-type'} key="idettypehead">
                Investment Type
            </th>
        );
    };

    renderInvestmentType = (_colInfo, _rowInfo, dataItems, itemIdx) => {
        const investment = dataItems[itemIdx];
        const key = 'idettype_' + (investment.isAggRow ? investment.type : investment._id);
        const accountId = this.props.account?._id ?? 'all';
        const onClick = investment.incomplete
            ? null
            : () => {
                  if (investment.isAggRow) {
                      this.dropdownAction(investment);
                  } else if (this.props.advisorVue) {
                      const pathPrefix = this.props.user.access === 'advisor' ? '/advisor/clients' : '/admin/users';
                      this.props.history.push(`${pathPrefix}/${this.props.match.params.id}/details/${investment._id}`);
                  } else {
                      this.props.history.push(`/accounts/${accountId}/investments/${investment._id}/details`);
                  }
              };

        if (isMobile)
            return (
                <div className={'mobile-investment-details-type'} key={key} onClick={onClick}>
                    <div style={{ fontSize: '14px', color: 'var(--color-light-gray)' }}>{investment.account?.name}</div>
                    <div>{investment.name}</div>
                </div>
            );
        return (
            <td className={'investment-details-type'} key={key} onClick={onClick}>
                <div style={{ fontSize: '14px', color: 'var(--color-light-gray)' }}>{investment.account?.name}</div>
                <div>{investment.name}</div>
            </td>
        );
    };

    renderDropDownHeader = () => {
        return isMobile ? <div className={'investment-details-dropdown-header'} key="iddhead"></div> : <th className={'investment-details-dropdown-header'} key="iddhead" />;
    };

    renderDropDownCell = (_colInfo, _rowInfo, dataItems, itemIdx) => {
        const item = dataItems[itemIdx];
        const key = item.isAggRow ? item.name : item._id;
        const dropDownState = !item.isAggRow || item.isSummaryRow ? '' : this.state.expanded.has(item.name) ? ' expanded' : ' collapsed';

        if (isMobile) {
            return dropDownState ? (
                <div onClick={() => this.dropdownAction(item)} className={'investment-details-dropdown-mobile' + dropDownState} key={key}>
                    {' '}
                    <span>See Investments</span>
                </div>
            ) : (
                ''
            );
        }
        return <td onClick={() => this.dropdownAction(item)} className={'investment-details-dropdown' + dropDownState} key={key} />;
    };

    renderGainLossCell = (_colInfo, _rowInfo, dataItems, itemIdx) => {
        const investment = dataItems[itemIdx];
        const currencySymbol = this.getCurrencySymbol(investment.currency);

        const key = 'gain-loss_' + itemIdx;
        if (investment.incomplete) {
            return isMobile ? <div key={key}></div> : <td key={key} />;
        }
        let className = isMobile ? 'altx-mobile-table-cell' : 'altx-table-cell';
        let displayAmount = '';
        let amount = investment.gainLossDollars;
        displayAmount = amount.toLocaleString('en-US', {
            maximumFractionDigits: 0,
        });
        // remove minus sign if "-0" (caused by rounding)
        if (parseInt(displayAmount) === 0) {
            displayAmount = displayAmount.replace('-', '');
            amount = 0;
        }
        if (displayAmount[0] === '-') {
            displayAmount = `-${currencySymbol}` + displayAmount.slice(1);
        } else {
            displayAmount = currencySymbol + displayAmount;
        }

        let posSigning = '';
        if (amount < 0) {
            className = className + ' investment-details-loss';
        } else if (amount > 0) {
            className = className + ' investment-details-gain';
            posSigning = '+';
        }
        displayAmount = posSigning + displayAmount;
        return isMobile ? (
            <div className={className} key={key}>
                <label>Gain Loss</label>
                {displayAmount}
            </div>
        ) : (
            <td className={className} key={key}>
                {displayAmount}{' '}
            </td>
        );
    };

    // builds display string for given IRR
    // IRR is already calculated in the buildDataItems function
    renderIRR = (_colInfo, _rowInfo, dataItems, itemIdx) => {
        const item = dataItems[itemIdx];
        const key = 'irr_' + itemIdx;
        let className = isMobile ? 'altx-mobile-table-cell' : 'altx-table-cell';
        let displayAmount = '';
        let amount = Number(item?.irr ?? 0);

        if (amount && amount > 1e12) amount = amount.toExponential(1);
        displayAmount = amount?.toLocaleString('en-US', {
            maximumFractionDigits: 1,
        });

        if (displayAmount === '-') displayAmount = displayAmount.slice(1);

        let posSigning = '';
        if (amount < 0) {
            className = className + ' investment-details-loss';
        } else if (amount > 0) {
            className = className + ' investment-details-gain';
            posSigning = '+';
        }
        displayAmount = posSigning + displayAmount;
        const isNum = !isNaN(amount);
        return isMobile ? (
            <div className={className} key={key}>
                <label>IRR</label>
                {displayAmount}%
            </div>
        ) : (
            <td className={className} key={key}>
                {isNum ? displayAmount : EM_DASH}
                {isNum ? '%' : ''}
            </td>
        );
    };

    toggleExpanded = (kind) => {
        this.setState((prevState) => {
            const newExpanded = new Set(prevState.expanded);
            if (newExpanded.has(kind)) {
                newExpanded.delete(kind);
            } else {
                newExpanded.add(kind);
            }
            return { expanded: newExpanded };
        }, this.buildRows);
    };

    getCurrency = (currency) => currency || this.props.currency || CURRENCIES.USD;

    getCurrencySymbol = (currency) => {
        return CURRENCY_SYMBOLS[this.getCurrency(currency)];
    };

    asDollarString = (amount, currency) => {
        if (!amount) {
            return EM_DASH;
        }

        let fractionDigits = 0;
        if (amount > -10 && amount < 10) {
            fractionDigits = 2;
        }
        const currencySymbol = this.getCurrencySymbol(currency);
        return (
            currencySymbol +
            amount.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
            })
        );
    };

    renderHeader = (columns, rowInfo, defaultRowRender) => {
        if (isMobile)
            return (
                <div>
                    {defaultRowRender(columns, rowInfo)}
                    {this.renderSelectorRow(columns)}
                </div>
            );
        return (
            <thead>
                {defaultRowRender(columns, rowInfo)}
                {this.renderSelectorRow(columns)}
            </thead>
        );
    };

    renderSelectorRow = (columns) => {
        if (isMobile)
            return (
                <div className="table-selector-row" onClick={() => alert('hi')}>
                    {columns.map((c, idx) => {
                        return <div key={'column-selector-' + idx}></div>;
                    })}
                </div>
            );

        return (
            <tr className="table-selector-row">
                {columns.map((c, idx) => {
                    return <th key={'column-selector-' + idx} />;
                })}
            </tr>
        );
    };

    checkIfComplete = (investment) => {
        // let hasOldData = false;
        // hasOldData = !Object.keys(investment.performance ?? {}).every((key) => {
        //     return Object.keys(investment.performance[key] ?? {}).every(
        //         (perfKey) => {
        //             if (investment.performance[key][perfKey] !== 0)
        //                 return false;
        //             return true;
        //         }
        //     );
        // });
        // return hasOldData || !investment.incomplete;
        return !investment.incomplete;
    };

    buildDataItems = (investmentList) => {
        let dataItems = [];
        const investmentDataItems = investmentList.map(getInvestmentStats);
        const typeDataItems = [];
        const types = new Set(investmentDataItems.map((i) => i.type));
        for (const t of types) {
            typeDataItems.push(this.aggregateBaseStats(investmentDataItems, t));
        }
        const totalDataItem = this.aggregateBaseStats(investmentDataItems);

        //Fill in derived stats
        investmentDataItems.forEach((idi) => this.calculateDerivedStats(idi, totalDataItem));
        typeDataItems.forEach((tdi) => this.calculateDerivedStats(tdi, totalDataItem));
        this.calculateDerivedStats(totalDataItem, totalDataItem);

        investmentDataItems.forEach((invBS) => this.rowIfy(invBS));
        typeDataItems.forEach((tdi) => this.rowIfy(tdi, true));
        this.rowIfy(totalDataItem, true, true);
        // sort type data rows
        this.sortData(typeDataItems);

        for (let tr of typeDataItems) {
            // remove total IRR
            tr.irr = tr.irr !== 0 ? (tr.irr * 100).toFixed(1) : EM_DASH;
            dataItems.push(tr);
            if (this.state.expanded.has(tr.type)) {
                const typeInvestmentDataItems = investmentDataItems.filter((ir) => ir.type === tr.type);
                this.sortData(typeInvestmentDataItems);
                dataItems.push(...typeInvestmentDataItems);
            }
        }
        totalDataItem.irr = totalDataItem.irr !== 0 ? (totalDataItem.irr * 100).toFixed(1) : EM_DASH;

        // const allActiveSuffix = this.getAllActiveSuffix();

        // totalDataItem.irr = this.props.account?.performance?.[`irr_by_account${allActiveSuffix}`]
        //     ? (this.props.account?.performance?.[`irr_by_account${allActiveSuffix}`] * 100).toFixed(1)
        //     : this.props.client?.performance?.[`irr_by_user${allActiveSuffix}`]
        //         ? (this.props.client?.performance?.[`irr_by_user${allActiveSuffix}`] * 100).toFixed(1)
        //         : this.props.user?.performance?.[`irr_by_user${allActiveSuffix}`]
        //             ? (this.props.user?.performance?.[`irr_by_user${allActiveSuffix}`] * 100).toFixed(1)
        //             : EM_DASH;
        dataItems.push(totalDataItem);

        return dataItems;
    };

    //Aggregate investment stats along a type; all if undefined type.
    aggregateBaseStats = (investmentBaseStats, type = undefined) => {
        if (type) {
            investmentBaseStats = investmentBaseStats.filter((i) => i.type === type);
        }

        const userOrAccount = this.props.account ?? this.props.relevantUser;
        // get irr data for the currency and show all or active
        const irr_data = userOrAccount?.performance?.irr_by_currency?.[this.props.currency]?.[this.props.globalInvestmentFilter === 'All' ? 'all' : 'active'];

        const ret = {
            name: type ?? 'Totals',
            type: type ?? 'Totals',
            committed: 0,
            amountCalled: 0,
            value: 0,
            distributions: 0,
            gain: 0,
            other: 0,
            accountPercent: 0,
            totalValue: 0,
            currentValue: 0,
            invested: 0,
            // irr:
            //     this.props.account?.performance?.[`irr_by_types${this.getAllActiveSuffix()}`]?.[type] ??
            //     this.props.relevantUser?.performance?.[`irr_by_types${this.getAllActiveSuffix()}`]?.[type] ??
            //     EM_DASH,
            irr: type ? irr_data?.irr_by_types[type] : irr_data?.irr_total,
        };

        for (const tis of investmentBaseStats) {
            this.accumulateBaseStats(ret, tis);
        }

        return ret;
    };

    //Adds base stats to accumulator's fields.
    accumulateBaseStats = (accumulator, baseStat) => {
        for (const stat of ['committed', 'amountCalled', 'value', 'distributions', 'other', 'gain', 'invested', 'currentValue']) {
            let statDatum = baseStat[stat];
            if (Number.isFinite(statDatum)) {
                accumulator[stat] += statDatum;
            }
        }
        accumulator.totalValue = accumulator.value + accumulator.distributions;
    };

    //Stats derived from those on investment object
    calculateDerivedStats = (investment, totals) => {
        const committed = investment?.performance?.itd?.committed ?? 0;
        investment.called = (investment.amountCalled / committed) * 100;
        investment.gainLossDollars = investment.gain;
        investment.gainLossPercent = (investment.gainLossDollars / investment.invested) * 100;

        //If percentage too small to display, don't display it.
        if (Math.abs(investment.invested) < 0.05) {
            investment.gainLossPercent = NaN;
        }

        investment.accountPercent = ((investment.value + investment.distributions) / totals.totalValue) * 100;
    };

    //Add fields for table styling
    rowIfy = (stats, isAgg = false, isSum = false) => {
        stats.isAggRow = isAgg;
        stats.isSummaryRow = isSum;
        return stats;
    };

    // sortState = {col, idx, ascending, field: col.sort?.field}
    onSort = (sortState) => {
        this.setState({ sortState });
    };

    sortData = (data) => {
        const field = this.state.sortState?.field;
        if (field) {
            const asc = !!this.state.sortState.ascending;

            if (field === 'gain') {
                const sortFunc = (a, b) => {
                    const prop = 'gainLossDollars';
                    const aValue = a[prop];
                    const bValue = b[prop];
                    return (aValue < bValue && asc) || (aValue > bValue && !asc) ? -1 : 1;
                };
                data = data.sort(sortFunc);
            } else if (field === 'totalValue') {
                const sortFunc = (a, b) => {
                    const aTotal = a.value + a.distributions;
                    const bTotal = b.value + b.distributions;
                    return (aTotal < bTotal && asc) || (aTotal > bTotal && !asc) ? -1 : 1;
                };
                data = data.sort(sortFunc);
            } else {
                const sortFunc = (a, b) => {
                    const aVal = a[field] ? (typeof a[field] === 'number' ? a[field] : a[field].toLowerCase()) : '';
                    const bVal = b[field] ? (typeof b[field] === 'number' ? b[field] : b[field].toLowerCase()) : '';
                    return (aVal < bVal && asc) || (aVal > bVal && !asc) ? -1 : 1;
                };
                data = data.sort(sortFunc);
            }
        }
        return data;
    };

    render = () => {
        let investments = this.props.investments;

        // console.log('investment details table render', this.props.investments);
        const dataItems = this.buildDataItems(investments);
        const last_irr_calculation =
            this.props.account?.performance?.last_irr_calculation ??
            this.props.relevantUser?.performance?.last_irr_calculation ??
            this.props.user?.performance?.last_irr_calculation ??
            null;
        return (
            <>
                <div id={isMobile ? 'mobile-investment-details-table' : 'investment-details-table'}>
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                        }}
                    >
                        <h1>Investment Details {this.props.currency ? ` - ${getCurrencyString(this.props.currency)}` : ''}</h1>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                }}
                                onClick={async () => {
                                    this.props.loading(null, 'investment-details-table-recalculate');
                                    await api.post(`/users/${this.props.relevantUser?._id ?? this.props.user?._id}/recalculate`, {
                                        run_user: true,
                                        run_accounts: true,
                                        run_async: true,
                                    });
                                    setDismissableAlert(this.props.setAlert, 'Recalculation will continue to run in the background.', false);
                                    this.props.loaded('investment-details-table-recalculate');
                                }}
                            >
                                <FontAwesomeIcon icon={faRotate} className={`account-overview-recalculate`} />
                            </div>
                            Performance As Of: {formatDate(last_irr_calculation, true, false, true)}
                        </div>
                    </div>
                    <Table
                        columns={this.columns()}
                        rowInfo={this.rowInfo}
                        items={dataItems}
                        renderHeader={this.renderHeader}
                        onSort={this.onSort}
                        tableStyle={{ left: '0px' }}
                        initialSortField="name"
                    />
                </div>
                <div style={{ clear: 'both', height: '50px' }} />
            </>
        );
    };

    columns = () => {
        const p = this.props;

        const columns = [
            {
                title: 'Investment Type',
                field: 'name',
                sort: {
                    field: 'name',
                },
                headerClass: isMobile ? 'mobile-investment-details-type' : 'investment-details-type',
                // renderHeader: this.renderInvestmentTypeHeader,
                render: this.renderInvestmentType,
            },
            {
                title: 'Invested',
                // title: this.props.user?.access === 'advisor' ? '% Called' : "Invested",
                sort: {
                    field: 'invested',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const investment = dataItems[itemIdx];
                    const key = 'invdet_invested_' + (investment?._id ?? investment?.name ?? String(itemIdx));
                    if (investment.incomplete) {
                        const innerSpan = <span style={{ marginTop: '-18px' }}>This investment is under administrative review</span>;
                        return isMobile ? (
                            <div key={key} className="admin-review-desc">
                                {innerSpan}
                            </div>
                        ) : (
                            <td key={key} className="admin-review-desc">
                                {innerSpan}
                            </td>
                        );
                    }
                    if (isMobile)
                        return (
                            <div className="altx-mobile-table-cell" key={key}>
                                <label>Invested</label>
                                <span>{this.asDollarString(investment.invested, investment.currency)}</span>
                            </div>
                        );
                    return (
                        <td className="altx-table-cell" key={key}>
                            {this.asDollarString(investment.invested, investment.currency)}
                        </td>
                    );
                },
            },
            {
                title: 'Total Value',
                sort: {
                    field: 'totalValue',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const investment = dataItems[itemIdx];
                    const key = 'invdet-total_value_' + (investment?._id ?? investment?.name ?? String(itemIdx));
                    if (investment.incomplete) {
                        return isMobile ? <div key={key}></div> : <td key={key} />;
                    }
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Total Value</label>
                            <span>{this.asDollarString(investment.totalValue, investment.currency)}</span>
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key}>
                            {this.asDollarString(investment.totalValue, investment.currency)}
                        </td>
                    );
                },
            },
            {
                title: 'Current Value',
                sort: {
                    field: 'currentValue',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const investment = dataItems[itemIdx];
                    const key = 'invdet-current_value_' + (investment?._id ?? investment?.name ?? String(itemIdx));
                    if (investment.incomplete) {
                        return isMobile ? <div key={key}></div> : <td key={key} />;
                    }
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Current Value</label>
                            <span>{this.asDollarString(investment.currentValue, investment.currency)}</span>
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key}>
                            {this.asDollarString(investment.currentValue, investment.currency)}
                        </td>
                    );
                },
            },
            {
                title: 'Gain/Loss',
                render: this.renderGainLossCell,
                sort: {
                    field: 'gain',
                },
            },
            {
                title: 'IRR',
                render: this.renderIRR,
                sort: {
                    field: 'irr',
                },
            },
            {
                title: '% Account',
                sort: {
                    field: 'accountPercent',
                },
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const item = dataItems[itemIdx];
                    const key = 'invdet-account-precent_' + (item?._id ?? item?.name ?? String(itemIdx));
                    if (item.incomplete) {
                        return isMobile ? <div key={key}></div> : <td key={key} />;
                    }

                    let displayStr = EM_DASH;
                    if (!isNaN(item?.accountPercent)) {
                        displayStr =
                            item.accountPercent.toLocaleString('en-US', {
                                maximumFractionDigits: 1,
                            }) + '%';
                    }

                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>% Account</label>
                            {displayStr}
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key}>
                            {displayStr}
                        </td>
                    );
                },
            },
        ];

        columns.push({
            title: 'Active',
            render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                const investment = dataItems[itemIdx];
                const key = 'invdet-active' + (investment?._id ?? investment?.name ?? String(itemIdx));
                // don't show anything for types, which don't have the 'inactive' property
                if (!Object.keys(investment).includes('inactive')) {
                    return isMobile ? <div key={key}></div> : <td key={key} />;
                }
                const value = investment.inactive ? 'No' : 'Yes';
                return isMobile ? (
                    <div className="altx-mobile-table-cell" key={key}>
                        <label>Active</label>
                        <span>{value}</span>
                    </div>
                ) : (
                    <td className="altx-table-cell" key={key}>
                        {value}
                    </td>
                );
            },
        });

        if (p.vue === VUES.ADMIN) {
            columns.push({
                title: 'Hidden',
                render: (_colInfo, _rowInfo, dataItems, itemIdx) => {
                    const investment = dataItems[itemIdx];
                    const key = 'invdet-hidden' + (investment?._id ?? investment?.name ?? String(itemIdx));
                    // don't show anything for types, which don't have the 'hidden' property
                    if (!Object.keys(investment).includes('hidden')) {
                        return isMobile ? <div key={key}></div> : <td key={key} />;
                    }
                    const value = investment.hidden ? 'Yes' : 'No';
                    return isMobile ? (
                        <div className="altx-mobile-table-cell" key={key}>
                            <label>Hidden</label>
                            <span>{value}</span>
                        </div>
                    ) : (
                        <td className="altx-table-cell" key={key}>
                            {value}
                        </td>
                    );
                },
            });
        }

        if (isMobile) {
            columns.push({
                // puts this as the last column for mobile
                title: '',
                renderHeader: this.renderDropDownHeader,
                render: this.renderDropDownCell,
            });
        } else {
            columns.unshift({
                // puts this as the first column for browser
                title: '',
                renderHeader: this.renderDropDownHeader,
                render: this.renderDropDownCell,
            });
        }
        return columns;
    };
}

export default withRouter(InvestmentDetailsTable);
