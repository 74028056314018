import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileTypes, getMimeTypes } from '../../../../constants/file';
import styles from './DropzoneContainer.module.scss';

interface DropzoneContainerProps {
    getChildren: (openFileDialog: () => void) => JSX.Element;
    onDrop: (files: any[]) => void;
    allowedFileTypes?: FileTypes[];
    disabled?: boolean;
    className?: string;
}

const DropzoneContainer: React.FC<DropzoneContainerProps> = ({ getChildren, onDrop, allowedFileTypes, disabled, className }) => {
    const handleDrop = useCallback(
        async (files: any[]) => {
            if (allowedFileTypes) {
                const isTypeAllowed = (file: any) => getMimeTypes(allowedFileTypes).includes(file.type);
                const invalidFiles = files.filter((file) => !isTypeAllowed(file));

                if (invalidFiles.length > 0) {
                    const invalidFileNames = invalidFiles.map((file) => file.name).join('\n');
                    window.alert(`These files have an invalid type:\n\n${invalidFileNames}`);
                    return;
                }
            }

            onDrop(files);
        },
        [allowedFileTypes, onDrop]
    );

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop: handleDrop,
        disabled,
        multiple: true,
        noDrag: true,
        noDragEventsBubbling: true,
        noClick: true,
        noKeyboard: true,
    });

    const renderDragging = () => (
        <div className={`${styles.bulkTrans_dragging}`}>
            <div className={`${styles.bulkTrans_docIcon_white}`} />
            Drop your commitments CSV here
        </div>
    );

    return (
        <div {...getRootProps({ className: `${styles.main} ${className}` })}>
            {getChildren?.(open)}
            {isDragActive && renderDragging()}
            <input {...getInputProps()} />
        </div>
    );
};

export default DropzoneContainer;
