import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GlobalPropertyKey, subscribeGlobalPropertyChange } from '../common/getSetGlobalProperty';
import useGlobalCurrency from '../common/useGlobalCurrency';
import { VUES } from '../constants/constantStrings';
import { getCurrencyString } from '../constants/currencyConstants';
import '../styles/title.css';
import { Currency } from '../types/Currency';
import { UserPermissions } from '../utilities/AdvisorVue/permissions';
import IconTextButton from './Buttons/IconTextButton';
import { ObjectFilterSelect } from './Dropdowns/ObjectFilterSelect';
import { AccountSearchFilter, InvestmentSearchFilter } from './Filters/GenericSearchFilter';
import HamburgerMenu from './Menus/HamburgerMenu';
import NotificationBell from './NotificationBell';
import GlobalInvestmentFilter from './Toggles/GlobalInvestmentStatusFilter';

// Define a type for the route parameters
interface RouteParams {
    investment?: string;
}

interface TitleProps extends RouteComponentProps<RouteParams> {
    title: string;
    user: { access: string };
    account: { _id: string; name: string } | null;
    investment: { _id: string; name: string } | null;
    relevantUser: { _id: string } | null;
    notifications: Array<{ read: boolean }>;
    showAccountSelector?: boolean;
    renderLogo?: boolean;
    setAccount: (account: { _id: string; name: string } | null) => void;
    setInvestment: (investment: { _id: string; name: string } | null) => void;
    setViewNotifications: () => void;
    vue: string;
}

const Title: FC<TitleProps> = ({
    title,
    user,
    account,
    investment,
    relevantUser,
    notifications = [],
    showAccountSelector = false,
    renderLogo = false,
    setAccount,
    setInvestment,
    setViewNotifications,
    vue,
    history,
    location,
    match,
}) => {
    // const [expandAccounts, setExpandAccounts] = useState(false);
    // const [expandInvestments, setExpandInvestments] = useState(false);
    // const [viewNotifications, setViewNotificationsState] = useState(false);

    const [currency, updateCurrency] = useGlobalCurrency();
    const [investmentCurrencies, setInvestmentCurrencies] = useState<Currency[]>([]);

    const isClientVue = location.pathname.includes('advisor/clients') || location.pathname.includes('admin/users');

    useEffect(() => {
        const unsubscribe = subscribeGlobalPropertyChange(GlobalPropertyKey.InvestmentCurrencies, (currencies: Currency[]) => {
            setInvestmentCurrencies(currencies);
        });
        return () => unsubscribe();
    }, []);

    // clear global currency if not on client vue
    useEffect(() => {
        if (!isClientVue && currency) {
            updateCurrency(null);
        }
    }, [currency, isClientVue, updateCurrency]);

    const byInvestmentSupported = () => {
        const routes = ['investments', 'calls', 'transactions'];
        const segments = location.pathname.split('/');
        if (segments.length < 2) return false;
        if (segments[1] !== 'accounts') return false;

        if (segments.length > 5 && segments[3] === 'investments' && routes.includes(segments[5])) return true;
        if (segments.length > 3 && routes.includes(segments[3])) return true;

        return false;
    };

    const byAccountsSupported = () => {
        const onProfile = location.pathname?.includes('profile');
        return user.access !== 'admin' && !onProfile;
    };

    const renderAddEntityOption = () => (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                width: '100%',
                cursor: 'unset',
            }}
            className="select-account-new1"
            onClick={(e) => {
                e.stopPropagation();
                history.push({
                    pathname: `/profile/${account?._id ?? 'all'}`,
                    state: { addEntity: true },
                });
            }}
        >
            <IconTextButton
                icon="/images/icons/plus.png"
                containerStyle={{
                    marginLeft: 0,
                    cursor: 'unset',
                }}
            />
            Add Investment Entity
        </div>
    );

    const setNewInvestment = (newInvestment: { _id: string; name: string } | null) => {
        setInvestment(newInvestment);

        if (vue === VUES.INVESTOR) {
            const segments = location.pathname.split('/') ?? [];
            if (segments.length > 2) {
                const investmentId = match.params.investment;
                if (!newInvestment?._id) {
                    const newPath = [...segments.slice(0, 3), ...segments.slice(-1)].join('/');
                    history.push(newPath);
                } else if (investmentId) {
                    const newPath = location.pathname.replace(investmentId, newInvestment._id);
                    history.push(newPath);
                } else {
                    const newPath = [...segments.slice(0, 3), 'investments', newInvestment._id, ...segments.slice(-1)].join('/');
                    history.push(newPath);
                }

                if (!newInvestment || investmentId === newInvestment._id) return;
                const path = location.pathname.replace(investmentId ?? '', newInvestment._id);
                history.push(path);
            }
        }
    };

    const renderAccountSelect = (setOpen: (open: boolean) => void) => {
        if (!relevantUser) return null;

        return (
            <div>
                <AccountSearchFilter
                    filter={{
                        users: [relevantUser._id],
                    }}
                    selected={account}
                    onChange={(account) => {
                        setAccount(account);
                        if (vue === VUES.INVESTOR) {
                            setInvestment(null);
                            const accountIdPath = location.pathname.split('/accounts/')[1]?.split('/')[0];
                            if (accountIdPath) {
                                const newPath = location.pathname.replace(`/accounts/${accountIdPath}`, `/accounts/${account?._id ?? 'all'}`);
                                history.push(newPath);
                            }
                        }
                        setOpen(false);
                    }}
                    defaultOptions={true}
                    width={'250px'}
                    excludeDisplayFields={['user']}
                    additionalOptions={vue === VUES.INVESTOR ? [renderAddEntityOption] : []}
                />
            </div>
        );
    };

    const renderCurrencySelect = (setOpen: (open: boolean) => void) => {
        if (!relevantUser || investmentCurrencies.length < 2) return null;

        return (
            <div>
                <ObjectFilterSelect
                    objects={investmentCurrencies}
                    getLabel={(currency) => getCurrencyString(currency)}
                    selected={currency}
                    onChange={(currency) => {
                        updateCurrency(currency?.value);
                        setOpen(false);
                    }}
                    width={'250px'}
                    defaultLabel="All Currencies"
                />
            </div>
        );
    };

    const renderInvestmentSelect = (setOpen: (open: boolean) => void) => {
        if (!relevantUser) return null;

        return (
            <div>
                <InvestmentSearchFilter
                    filter={{
                        users: [relevantUser._id],
                        ...(account && { accounts: [account._id] }),
                    }}
                    selected={Array.isArray(investment) ? investment[0] : investment || undefined}
                    onChange={(investment) => {
                        setNewInvestment(Array.isArray(investment) ? investment[0] : investment);
                        setOpen(false);
                    }}
                    defaultOptions={true}
                    width={'250px'}
                    excludeDisplayFields={[...(vue === VUES.INVESTOR ? (['ownership'] as const) : [])]}
                    aria-label="investment selector"
                />
            </div>
        );
    };

    const renderRightHeader = (asColumn: boolean = false, setOpen: (open: boolean) => void = () => {}) => {
        const headerStyle = { display: 'flex', flexDirection: asColumn ? 'column' : 'row', gap: '10px' } as React.CSSProperties;
        if (vue === VUES.INVESTOR) return renderInvestorRightHeader(setOpen, headerStyle);
        if (vue === VUES.ADVISOR) return renderAdvisorRightHeader(setOpen, headerStyle);
        if (vue === VUES.ADMIN) return renderAdminRightHeader(setOpen, headerStyle);
    };

    const renderMenu = () => {
        return (
            <HamburgerMenu
                menuStyle={{ left: 'unset', right: '0px', top: '50px' }}
                options={[
                    {
                        renderComponent: (setOpen) => renderRightHeader(true, setOpen),
                    },
                ]}
            />
        );
    };

    const renderAdvisorLogoAndNotificationsBell = () => {
        const renderAdvisorGroupLogo = () => {
            const logo = UserPermissions()?.advisor_logo_thumb;
            const showLogo = UserPermissions()?.advisor_logo_in_toolbar;
            if (!logo || !showLogo) return null;
            return <img style={{ height: '60px', marginRight: '15px' }} src={logo} alt="advisor group logo" />;
        };
        const renderArrows = () => {
            return (
                <>
                    <img src="/images/icons/advisor/advisorTitleLeftArrow.png" alt="Left Arrow" />
                    <img src="/images/icons/advisor/advisorTitleRightArrow.png" alt="Right Arrow" />
                </>
            );
        };
        return (
            <>
                {renderLogo && (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0px',
                            right: 80,
                            position: 'absolute',
                            justifyContent: 'right',
                        }}
                    >
                        {renderAdvisorGroupLogo() || renderArrows()}
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0px',
                                justifyContent: 'right',
                            }}
                        >
                            {/* {renderArrows()} */}
                            <img src="/images/icons/advisor/ADVISOR.png" alt="advisor" />
                            <img src="/images/icons/advisor/VUE.png" alt="VUE" style={{ marginRight: '0px' }} />
                        </div>
                    </div>
                )}
                <div style={{ position: 'absolute', right: 30 }}>
                    <NotificationBell
                        count={notifications?.filter((n) => !n.read).length ?? 0}
                        onClick={() => {
                            setViewNotifications();
                        }}
                        height={30}
                        aria-label="notifications"
                    />
                </div>
            </>
        );
    };

    const renderInvestorRightHeader = (setOpen: (open: boolean) => void, headerStyle?: React.CSSProperties) => {
        const showSelectInvestment = byInvestmentSupported();
        const showSelectAccount = byAccountsSupported() && showAccountSelector;

        return (
            <div className="titleNotification" style={{ ...headerStyle }}>
                {showSelectInvestment && <div style={{ zIndex: 101 }}>{renderInvestmentSelect(setOpen)}</div>}
                {showSelectAccount && <div style={{ zIndex: 100 }}>{renderAccountSelect(setOpen)}</div>}
                <GlobalInvestmentFilter containerStyle={{}} />
                {renderCurrencySelect(setOpen)}
            </div>
        );
    };

    const renderAdvisorRightHeader = (setOpen: (open: boolean) => void, headerStyle?: React.CSSProperties) => {
        return (
            <div>
                {!isClientVue && (
                    <div className="titleNotification" style={{ ...headerStyle }}>
                        {renderAdvisorLogoAndNotificationsBell()}
                    </div>
                )}
                {isClientVue && (
                    <div
                        className="titleNotification"
                        style={{
                            gap: '10px',
                            ...headerStyle,
                        }}
                    >
                        {renderAccountSelect(setOpen)}
                        <GlobalInvestmentFilter containerStyle={{}} />
                        {renderCurrencySelect(setOpen)}
                    </div>
                )}
            </div>
        );
    };

    const renderAdminRightHeader = (setOpen: (open: boolean) => void, headerStyle?: React.CSSProperties) => {
        return (
            isClientVue && (
                <div
                    className="titleNotification"
                    style={{
                        gap: '10px',
                        ...headerStyle,
                    }}
                >
                    <div style={{ zIndex: 100 }}>{renderAccountSelect(setOpen)}</div>
                    <GlobalInvestmentFilter containerStyle={{ marginBottom: '-2px' }} onChange={(newValue) => setOpen(false)} />
                    {renderCurrencySelect(setOpen)}
                </div>
            )
        );
    };

    const renderCurrentCurrency = () => {
        return (
            <div>
                <span>{getCurrencyString(currency)}</span>
            </div>
        );
    };

    return (
        <div className="title noselect" data-testid="title-component" data-exclude-fields={vue === 'INVESTOR' ? 'ownership' : ''}>
            <div>{title}</div>

            <div className="title-selectors">
                {!isClientVue && renderRightHeader(false)}
                {renderCurrentCurrency()}
                {isClientVue && renderMenu()}
            </div>
        </div>
    );
};

export default withRouter(Title);
