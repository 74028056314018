import _ from 'lodash';
import { getSetGlobalProperty, GlobalPropertyKey } from '../common/getSetGlobalProperty';
import { Investment } from '../types';
import { Currency, CurrencySymbol } from '../types/Currency';

export const CURRENCIES: { [key in Currency]: Currency } = { ...Currency };

export const CURRENCY_SYMBOLS: { [key in Currency]: CurrencySymbol } = { ...CurrencySymbol };

export const getCurrencyString = (currency: Currency): string => {
    if (!currency) return '';
    const symbol = CURRENCY_SYMBOLS[currency] || CURRENCY_SYMBOLS.USD;
    return `${currency} (${symbol})`;
};

export const getCurrenciesFromInvestments = (investments: Investment[]): Currency[] => {
    if (!investments) return [];
    const currencySet = new Set(investments.map((i) => i.currency));
    const currencies = Array.from(currencySet);
    currencies.sort((a, b) => Object.values(Currency).indexOf(a) - Object.values(Currency).indexOf(b));
    return currencies;
};

// sets the global currency and investment currencies based on the investments
// if the current global currency is not in the investment currencies, set it to null
// if the current global investment currencies are different from the investment currencies, set it to the investment currencies
export const setGlobalCurrenciesFromInvestments = (investments: Investment[]) => {
    const investment_currencies = getCurrenciesFromInvestments(investments);
    // if the current global currency is not in the investment currencies, set it to null
    const currentGlobalCurrency = getSetGlobalProperty(GlobalPropertyKey.Currency);
    if (currentGlobalCurrency && !investment_currencies.includes(currentGlobalCurrency)) {
        getSetGlobalProperty(GlobalPropertyKey.Currency, null);
    }
    // if the current global investment currencies are different from the investment currencies, set it to the investment currencies
    const currentGlobalInvestmentCurrencies = getSetGlobalProperty(GlobalPropertyKey.InvestmentCurrencies);
    if (currentGlobalInvestmentCurrencies && !_.isEqual(currentGlobalInvestmentCurrencies.sort(), investment_currencies.sort())) {
        getSetGlobalProperty(GlobalPropertyKey.InvestmentCurrencies, investment_currencies);
    }
    return {
        investment_currencies,
    };
};
