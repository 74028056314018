import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import GenericDrawer from './GenericDrawer';

import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';

interface ShareClass {
    _id: string;
    name: string;
    investment_master_id: string;
    gdm_id?: string;
    dlt_id?: string;
}

interface InvestmentMaster {
    _id: string;
    name: string;
}

export interface Props extends Partial<RouteComponentProps> {
    shareClass?: ShareClass;
    investmentMasterId?: string;
    loading: (time?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (message: string, isError?: boolean) => void;
    close: () => void;
    setErrors?: (errors: Record<string, string>) => void;
    reloadData: (() => Promise<void>) | undefined;
}

interface CommonData {
    name?: string;
    investment_master_id?: string;
    gdm_id?: string;
    dlt_id?: string;
}

const AddEditShareClassDrawer: React.FC<Props> = (props) => {
    const [commonData, setCommonData] = useState<CommonData>({});
    const [localErrors, setLocalErrors] = useState<Record<string, string>>({});
    const [investmentMasters, setInvestmentMasters] = useState<InvestmentMaster[]>([]);
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const loadInvestmentMasters = async () => {
        try {
            const response = await api2.client.InvestmentMasterApi.listInvestmentMasters({ limit: 100 });
            if (response.data && response.data.success && response.data.investment_masters) {
                const masters = response.data.investment_masters
                    .filter((im): im is { _id: string; name: string } => im._id !== null && im._id !== undefined && im.name !== null && im.name !== undefined)
                    .map((im) => ({
                        _id: im._id,
                        name: im.name,
                    }));
                setInvestmentMasters(masters);
            }
        } catch (error) {
            console.error('Error loading investment masters:', error);
            props.setAlert('Failed to load investment masters', true);
        }
    };

    const loadData = async () => {
        if (!mounted.current) return;

        props.loading?.(0, 'addEditShareClassDrawer');

        try {
            let data = {};

            if (props.shareClass) {
                data = {
                    name: props.shareClass.name,
                    investment_master_id: props.shareClass.investment_master_id,
                    gdm_id: props.shareClass.gdm_id,
                    dlt_id: props.shareClass.dlt_id,
                };
            } else if (props.investmentMasterId) {
                data = {
                    investment_master_id: props.investmentMasterId,
                };
            }

            if (mounted.current) {
                setCommonData(data);
            }

            // Load investment masters if we're creating a new share class
            if (!props.shareClass) {
                await loadInvestmentMasters();
            }

            props.loaded?.('addEditShareClassDrawer');
            return data;
        } catch (error) {
            console.error('Error loading share class data:', error);
            if (mounted.current) {
                setLocalErrors((prev) => ({
                    ...prev,
                    submit: 'Error loading share class data',
                }));
            }
            props.loaded?.('addEditShareClassDrawer');
            return {};
        }
    };

    // Load initial data
    useEffect(() => {
        loadData();
    }, [props.shareClass, props.investmentMasterId]);

    const getFields = (state: any, setState: any) => {
        const fields: Record<string, any> = {
            name: {
                label: 'Share Class Name',
                placeholder: 'Enter Share Class Name',
                fieldName: 'name',
                type: 'text',
                required: true,
            },
            gdm_id: {
                label: 'GDM ID',
                placeholder: 'Enter GDM ID',
                fieldName: 'gdm_id',
                type: 'text',
            },
            dlt_id: {
                label: 'DLT ID',
                placeholder: 'Enter DLT ID',
                fieldName: 'dlt_id',
                type: 'text',
            },
        };

        // Only add investment master selection for new share classes
        if (!props.shareClass) {
            fields.investment_master_id = {
                label: 'Investment Master',
                placeholder: 'Select Investment Master',
                fieldName: 'investment_master_id',
                type: 'select',
                options: investmentMasters,
                optionField: 'name',
                optionClearable: false,
                required: true,
            };
        }

        return fields;
    };

    const checkCommonData = (data: CommonData, errors: Record<string, string>) => {
        if (!data.name || data.name.trim() === '') errors['name'] = 'Share class name is required.';
        if (!data.investment_master_id) {
            errors['investment_master_id'] = 'Investment master is required.';
        } else if (typeof data.investment_master_id === 'string' && data.investment_master_id.trim() === '') {
            errors['investment_master_id'] = 'Investment master is required.';
        }
        if (!data.gdm_id || data.gdm_id.trim() === '') errors['gdm_id'] = 'GDM ID is required.';
        if (!data.dlt_id || data.dlt_id.trim() === '') errors['dlt_id'] = 'DLT ID is required.';
    };

    const isSafeToSave = (setErrors = true, data: CommonData): boolean => {
        let errors: Record<string, string> = {};
        checkCommonData(data, errors);
        const isSafe = Object.keys(errors).length === 0;
        if (setErrors) {
            errors.submit = isSafe ? '' : 'There are missing required fields.';
            setLocalErrors(isSafe ? {} : errors);
        }
        return isSafe;
    };

    const createOrUpdateShareClass = async (data: CommonData) => {
        const mergedData = {
            ...commonData,
            ...data,
        };

        // Extract investment master ID if it's an object
        if (mergedData.investment_master_id && typeof mergedData.investment_master_id === 'object') {
            mergedData.investment_master_id = (mergedData.investment_master_id as InvestmentMaster)._id;
        }

        if (!isSafeToSave(true, mergedData)) {
            props.loaded('addEditShareClassDrawer-createOrUpdateShareClass');
            return;
        }

        props.loading(320, 'addEditShareClassDrawer-createOrUpdateShareClass');

        try {
            if (props.shareClass?._id) {
                // Update existing share class
                const response = await api2.client.ShareClassApi.updateShareClass({
                    investment_master_id: mergedData.investment_master_id as string,
                    share_class_id: props.shareClass?._id,
                    UpdateShareClassRequest: {
                        name: mergedData.name as string,
                        dlt_id: mergedData.dlt_id as string,
                        gdm_id: mergedData.gdm_id as string,
                    },
                });

                if (response?.data.success) {
                    props.setAlert('Share class updated successfully');
                    props?.reloadData?.();
                    props.close();
                }
            } else {
                // Create new share class
                const response = await api2.client.ShareClassApi.createShareClass({
                    investment_master_id: mergedData.investment_master_id as string,
                    CreateShareClassRequest: {
                        name: mergedData.name as string,
                        dlt_id: mergedData.dlt_id as string,
                        gdm_id: mergedData.gdm_id as string,
                    },
                });

                if (response?.data.success) {
                    props.setAlert('Share class created successfully');
                    props?.reloadData?.();
                    props.close();
                }
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'An error occurred';

            if (errorMessage === 'Duplicate key error') {
                setDismissableAlert(props.setAlert, 'Share class already exists', true);
            } else {
                setDismissableAlert(props.setAlert, 'Failed to create share class', true);
                setLocalErrors((prev) => ({
                    ...prev,
                    submit: errorMessage,
                }));
            }
        } finally {
            props.loaded('addEditShareClassDrawer-createOrUpdateShareClass');
        }
    };

    const onFieldChange = (field: string, value: any) => {
        const updatedData = {
            ...commonData,
            [field]: value,
        };
        setCommonData(updatedData);
        // Clear any existing errors for this field
        setLocalErrors((prev) => {
            const newErrors = { ...prev };
            delete newErrors[field];
            return newErrors;
        });
    };

    const deleteShareClass = async () => {
        console.log('props.shareClass', props.shareClass);
        if (!props.shareClass?._id || !props.shareClass?.investment_master_id) {
            console.log('no share class id or investment master id');
            return;
        }

        props.loading(320, 'addEditShareClassDrawer-deleteShareClass');

        try {
            const response = await api2.client.ShareClassApi.deleteShareClass({
                investment_master_id: props.shareClass.investment_master_id,
                share_class_id: props.shareClass._id,
            });

            if (response?.data.success) {
                props.setAlert('Share class deleted successfully');
                props?.reloadData?.();
                props.close();
            }
        } catch (error: any) {
            console.log('error', error);
            const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
            setDismissableAlert(props.setAlert, `Failed to delete share class: ${errorMessage}`, true);
        } finally {
            props.loaded('addEditShareClassDrawer-deleteShareClass');
        }
    };

    return (
        <GenericDrawer
            title={props.shareClass ? 'Edit Share Class' : 'Add Share Class'}
            actionStr={props.shareClass ? 'Update' : 'Create'}
            loadingKey="addEditShareClassDrawer"
            getFields={getFields}
            onSubmit={createOrUpdateShareClass}
            close={props.close}
            loading={props.loading}
            loaded={props.loaded}
            loadData={loadData}
            errors={localErrors}
            setErrors={setLocalErrors}
            isSafeToSave={isSafeToSave}
            onFieldChange={onFieldChange}
            formData={commonData}
            onDelete={props.shareClass ? deleteShareClass : undefined}
        />
    );
};

export default AddEditShareClassDrawer;
