import { useEffect, useState } from 'react';
import { Currency } from '../types/Currency';
import { getSetGlobalProperty, GlobalPropertyKey, GlobalPropertyType, subscribeGlobalPropertyChange } from './getSetGlobalProperty';

export const subscribeGlobalCurrencyChange = (callback: (value: GlobalPropertyType) => void): (() => void) => {
    return subscribeGlobalPropertyChange(GlobalPropertyKey.Currency, callback);
};

export const getGlobalCurrency = (): GlobalPropertyType => {
    return getSetGlobalProperty(GlobalPropertyKey.Currency);
};

const useGlobalCurrency = () => {
    const [currency, setCurrency] = useState(() => getSetGlobalProperty(GlobalPropertyKey.Currency));

    const updateCurrency = (newCurrency: GlobalPropertyType) => {
        setCurrency(getSetGlobalProperty(GlobalPropertyKey.Currency, newCurrency));
    };

    useEffect(() => {
        const unsubscribe = subscribeGlobalCurrencyChange(setCurrency);
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = subscribeGlobalPropertyChange(GlobalPropertyKey.InvestmentCurrencies, (currencies: Currency[]) => {
            if (currencies.length === 0) {
                setCurrency(getSetGlobalProperty(GlobalPropertyKey.Currency, null));
            } else if (currencies.length === 1) {
                setCurrency(getSetGlobalProperty(GlobalPropertyKey.Currency, currencies[0]));
            }
        });
        return () => unsubscribe();
    }, []);

    return [currency, updateCurrency];
};

export default useGlobalCurrency;
